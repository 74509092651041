<template>
    <div class="card border border-secondary card-margin">
        <div class="card-header">
            <h4 class="text-dark text-center">DNS</h4>
        </div>
        <div class="card-body device-row">
            <div class="row" v-for="(item, key, index) in dns" :key="index">
                <p class="col-3">{{key}}</p>
                <p class="col-9">{{item}}</p>
            </div>
        </div>
    </div>
    <div class="card border border-secondary card-margin" v-for="(item, key, index) in devices" :key="index">
        <div class="card-header">
            <h4 class="text-dark text-center">{{key}}</h4>
        </div>
        <div class="card-body device-row">
            <div class="row w-100" v-for="(item, key, index) in item" :key="index">
                <p class="col-3">{{key}}</p>
                <p class="col-9" v-if="(typeof item === 'string')">{{item}}</p>
                <template v-else>
                    <p class="col-9">
                        <template v-for="(obj, index) in item" :key="index">
                            {{obj}}
                            <br/>
                        </template>
                    </p>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { NetworkInfo } from "@/models/Logs"
import { defineComponent, PropType } from "vue"

export default defineComponent({
    name: 'DeviceNetworking',
    props: {
        network: {
			type: Object as PropType<NetworkInfo>,
			required: true
		}
    },
    data() {
        return {}
    },
    components: {
    },
    methods: {
    },
    computed: {
        dns() {
            if (!this.network) {
                return {}
            }

            return this.network["DNS configuration"]
        },
        devices() {
            if (!this.network) {
                return {}
            }

            return this.network.devices
        }
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .card-margin {
        min-width: 240px;
        margin: 8px;
    }

    .device-row div:nth-of-type(even) {
        background: #464f5b;
    }

</style>
