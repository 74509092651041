<template>
    <div class="card border border-secondary m-1">
        <div class="card-header">
            <h4 class="text-success text-center">PORTAL</h4>
        </div>
        <div class="card-body col-12">
            <template v-if="!ready">
                <div class="row">
                    <div class="spinner-grow avatar-lg text-info col-4 loading-spinner" role="status">
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="row" v-for="portal in portals" :key="portal.name">
                    <h4 class="col-1">{{ portal.name}}</h4>
                    <template v-if="portal?.last_response?.status == 200">
                        <h4 class="col-2 text-center" :style=get_status_style(portal)>{{ get_status_name(portal) }}</h4>
                        <h4 class="col-9 text-info">{{ portal?.last_response?.content?.options?.connect_url }}</h4>
                    </template>
                    <template v-else>
                        <h4 class="col-2 text-center" :style=get_status_style(portal)>{{ portal?.status }}</h4>
                        <h4 class="col-9 text-info">{{ portal?.last_response?.error??"UNKNOWN" }}</h4>
                    </template>
                </div>
            </template>
        </div>
        <div class="card-footer text-center">
            <h4 :style="get_style">{{get_status}}</h4>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { NotificationColors } from "@/models/UI"
import { hosturl } from "@/models/hub"

interface PortalInfo {
    name: string
    url: string
    status: number
    cert_info: Record<string, unknown>
    last_response: {
        error?: string
        content: {
            options: {
                connect_url: string
            }
        }
        status: number
    }
}

export default defineComponent({
    name: 'PortalStatus',
    props: {
        qa: Boolean,
        eu: Boolean,
        us: Boolean,
        ca: Boolean,
    },
    data() {
        return {
            ready: false,
            error: '',
            portals: Array<PortalInfo>()
        }
    },
    components: {
    },
    computed: {
        get_status() : string {
            for (const portal of this.portals) {
                if (portal.last_response == undefined) {
                    return "NA"
                }

                if (portal.status != 200) {
                    return "FAILURE"
                }
            }
            return `ONLINE`            
        },     
        get_style() : string {
            for (const portal of this.portals) {
                if (portal.last_response == undefined) {
                    return `color: ${NotificationColors.DISABLED}`
                }

                if (portal.status != 200) {
                    return `color: ${NotificationColors.FAIL}`
                }
            }

            return `color: ${NotificationColors.PASS}`
        },        
    },
    methods: {        
        async check_portals() {
            this.ready = false

            this.portals = Array<PortalInfo>()

            if (this.qa)
                this.portals.push({ name: "QA", url: "/api/portal/check/qa", last_response: {} } as PortalInfo)

            if (this.us)
                this.portals.push({ name: "US", url: "/api/portal/check/us", last_response: {} } as PortalInfo)

            if (this.eu)
                this.portals.push({ name: "EU", url: "/api/portal/check/eu", last_response: {} } as PortalInfo)

            if (this.ca)
                this.portals.push({ name: "CA", url: "/api/portal/check/ca", last_response: {} } as PortalInfo)

            for (const portal of this.portals) {                
                await fetch(`${hosturl}${portal.url}`)
                    .then(response => {
                        portal.status = response.status
                        return response.json()
                    })
                    .then(portal_response => {
                        Object.assign(portal, portal_response)
                    })
                    .catch(error => {
                        this.error = error
                    })
            }
            this.ready = true
        },
        get_status_style(portal: PortalInfo) : string {
            if (portal.status === undefined) {
                return `color: ${NotificationColors.DISABLED}`
            }

            if (portal.status != 200) {
                return `color: ${NotificationColors.FAIL}`
            }

            return `color: ${NotificationColors.PASS}`
        },
        get_status_name(portal: PortalInfo) : string {
            if (portal.status === undefined) {
                return "NA"
            }

            if (portal.status != 200) {
                return "FAIL"
            }

            return "OK"
        },    
    },
    async mounted() {
        await this.check_portals()
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .card-margin {
        margin: 8px;
    }

    .loading-spinner {
        margin: auto;
        width: 150px;
        height: 150px;
    }
</style>
