import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Home from '@/views/Home.vue'
import SlingBalenaReport from '@/views/SlingBalenaReport.vue'
import CometBalenaReport from '@/views/CometBalenaReport.vue'
import DeviceDashboard from '@/views/DeviceDashboard.vue'
import CertReport from '@/views/CertReport.vue'
import FleetDevices from '@/views/FleetDevices.vue'
import SSOLogin from '@/views/SSOLogin.vue'
import { store } from '@/models/hub'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/device/:uuid',
    name: 'Device Dashboard',
    component: DeviceDashboard
  },
  {
    path: '/device/:fleet/:uuid',
    name: 'Fleet Device Dashboard',
    component: DeviceDashboard
  },
  {
    path: '/:fleet/devices',
    name: 'Fleet devices',
    component: FleetDevices
  },
  {
    path: '/reports/balena/sling',
    name: 'Balena Sling Report',
    component: SlingBalenaReport
  },
  {
    path: '/reports/balena/comet/:fleet',
    name: 'Balena Comet Report',
    component: CometBalenaReport
  },
  {
    path: '/reports/device/certs/:fleet',
    name: 'Device Certs Report',
    component: CertReport
  },
  {
    path: '/login',
    name: 'SSO Login',
    component: SSOLogin
  },  
]

const saml_routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: SSOLogin
  },
  {
    path: '/login',
    name: 'SSO Login',
    component: SSOLogin
  },
]

await store.dispatch("fetchConfig");

const access_info = store.state.config.access

let router =  createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes: saml_routes,
})

if (access_info.account.logged_in) {
  router = createRouter({
    history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
    routes: routes,
  })  
}

router.beforeEach((to, from, next) => {
  const title : string = to.meta.title as string
  
  if (title) {
    document.title = title
  } else {
    const build : string = process.env.VUE_APP_BUILD_VERSION ?? "NA.NA.NA"
    document.title = `COMET-HUB [${build?.slice(0,10)}]`
  }

  if (to.path != "/login" && access_info.account.logged_in !== true) {
    next("/login")
  }

  next()
})

export default router
