<template>
    <div class="card border border-secondary card-margin">
        <div class="card-header">
            <h4 class="text-dark text-center">BANDWIDTH</h4>
        </div>
        <div class="row align-self-center">
            <gauge :label="get_label(bandwidth)" :color="get_color(bandwidth)" :reading="bandwidth.percent" type="radialBar" />
        </div>
        <div class="card-footer">
            <h5 class="text-dark text-center">{{get_video(bandwidth)}}</h5>
        </div>
    </div>
</template>

<script lang="ts">
import { TransportInfo } from "@/models/Logs"
import { defineComponent } from "vue"
import Gauge from '@/components/Gauge.vue'
import { NotificationColors } from "@/models/UI"

interface Bandwidth {
    samples:         number
    bytes:           number
    bytes_per_sec:   number
    bytes_per_min:   number
    start:           number
    end:             number
    speed:           number
    percent:         number
}

export default defineComponent({
    name: 'DeviceBandwidthGauge',
    props: {
        transport: {
			type: Array as ()=>Array<TransportInfo> | undefined,
			required: true
		}
    },
    data() {
        return {
        }
    },
    components: {
        Gauge
    },
    methods: {
        get_label(bandwidth : Bandwidth) {
            if (bandwidth.percent <= 0) {
                return "NA"
            }

            return `${bandwidth.speed / 131072} Mbps`
        },
        get_video(bandwidth : Bandwidth) {
            if (bandwidth.percent <= 0) {
                return "NA"
            }

            return `1 Minute ~${Math.round(bandwidth.bytes_per_min * 100 / 1048576) / 100} MB`
        },
        get_color(bandwidth : Bandwidth) : string {
            if (bandwidth.bytes_per_sec <= 0) {
                return NotificationColors.DISABLED
            }

            if (bandwidth.bytes_per_sec > 1048576) {
                return NotificationColors.FAIL
            }

            if (bandwidth.bytes_per_sec > 104857.6) {
                return NotificationColors.WARN
            }

            return NotificationColors.PASS
        },        
    },
    computed: {        
        bandwidth() {
            const bandwidth : Bandwidth = {
                samples: 0,
                bytes: 0,
                bytes_per_sec: 0,
                bytes_per_min: 0,
                start: Date.now(),
                end: 0,
                speed: 0,
                percent: 0
            }

            let last_bytes : number | undefined = undefined

            this.transport?.forEach(item => {
                if (item.ts === undefined) {
                    return
                }

                if (last_bytes !== undefined) {
                    bandwidth.bytes += Math.abs(item.BytesTx - last_bytes)
                }

                if (bandwidth.start > item.ts) {
                    bandwidth.start = item.ts
                }

                if (bandwidth.end < item.ts) {
                    bandwidth.end = item.ts
                }

                last_bytes = item.BytesTx
                bandwidth.samples ++
            });

            bandwidth.bytes_per_sec = bandwidth.bytes / (bandwidth.end - bandwidth.start)
            bandwidth.bytes_per_min  = bandwidth.bytes_per_sec * 60

            bandwidth.speed = 131072
            bandwidth.percent = Math.round((bandwidth.bytes_per_sec * 100) / bandwidth.speed)

            while (bandwidth.percent > 100) {
                bandwidth.speed = bandwidth.speed * 10;
                bandwidth.percent = Math.round((bandwidth.bytes_per_sec * 100) / bandwidth.speed)
            }

            return bandwidth
        }
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .card-margin {
        margin: 8px;
    }
</style>
