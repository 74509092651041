<template>
    <apexcharts :class="get_classes" 
                :width="width" 
                :height="height" 
                type="line"
                :id="id"
                :series="series"
                :options="options">
    </apexcharts>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import VueApexCharts from "vue3-apexcharts"
import { ProcessorTools, TransportInfo } from "@/models/Logs"
import { GraphingColors } from "@/models/UI"

export default defineComponent({
    name: 'DevicePendingGraph',
    props: {
        classes: String,
        width: String,
        height: String,
        id: String as () => "device-pending-graph",
        logs: Array as () => Array<TransportInfo>
    },
    data() {
        return {
        }
    },
    components: {
        apexcharts: VueApexCharts
    },
    methods: {
        build_buffer_sets(logs : TransportInfo[] | undefined) {
            const buffer_sets : 
            {
                [key: string] : number[]
            } = {}

            const buffer_stamps : number[] = []

            buffer_sets['PENDING BUFFERS'] = []
            buffer_sets['PENDING BYTES'] = []
            buffer_sets['TX BYTES'] = []
            buffer_sets['RX BYTES'] = []

            let last_tx_bytes : number | undefined = undefined
            let last_rx_bytes : number | undefined = undefined
            let last_pending_bytes : number | undefined = undefined

            logs?.forEach(log=>{
                if (log.ts === undefined) {
                    return
                }

                if (last_pending_bytes !== undefined &&
                    last_tx_bytes !== undefined &&
                    last_rx_bytes !== undefined)
                {
                    buffer_stamps.push(ProcessorTools.get_epoch_date(log.ts).getTime())
                    buffer_sets['PENDING BUFFERS'].push(log.Pending)
                    buffer_sets['PENDING BYTES'].push(Math.abs(log.PendingBytes - last_pending_bytes))
                    buffer_sets['TX BYTES'].push(Math.abs(log.TotalTx - last_tx_bytes))
                    buffer_sets['RX BYTES'].push(Math.abs(log.TotalRx - last_rx_bytes))
                }

                last_tx_bytes = log.TotalTx
                last_rx_bytes = log.TotalRx
                last_pending_bytes = log.PendingBytes
            })

            return {
                sets: buffer_sets,
                stamps: buffer_stamps
            }
        }
    },
    computed: {
        buffer_sets() {
            return this.build_buffer_sets(this.logs)
        },
        options() {
            return {
                chart: {
                    id: this.id,
                    background: '#37404a'
                },
                xaxis: {
                    type: 'datetime',
                    label: {
                        show: true,
                    }
                },
                theme: {
                    mode: 'dark',
                }                
            };
        },
        series() {
            const temp_series = this.buffer_sets
            const colors = GraphingColors
            const series = []
            let index = 0

            for (const key in temp_series.sets) {
                series.push({
                    name: key,
                    color: colors[index % colors.length],
                    data: temp_series.sets[key].map((temp,i)=>{ return [this.buffer_sets.stamps[i], temp]})
                })
                index ++
            }

            return series
        },        
        get_classes() {
            if (this.classes === undefined) {
                return "w-100"
            }

            return this.classes
        }
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
