<template>
    <div class="w-100 col-12 p-0">
        <div class="card border border-secondary">
            <div class="card-header">
                <h4 class="text-dark text-center">DEVICE CERTIFICATE</h4>
            </div>
            <div class="card-body w-100 text-success">
                <form enctype="multipart/form-data">
                    <div class="row h-100 align-items-center p-2">
                        <div :class="{'col-5' : true, 'text-dark' : true, 'text-danger' : true, 'h-100': true, 'p-1' : true }">PFX PASSWORD</div>
                        <input type="password" class="form-control col-7 p-1 text-right" name="password" v-model="pfx_password">
                    </div>
                    <div class="row font-weight-bold p-0">
                        <h4 class="card-header col-12 text-center text-info">PFX FILE</h4>
                    </div>
                    <div class="row p-2">
                        <span class="col-12 btn btn-outline-info h-100">
                            <input  type="file" 
                                    name="pfx_file" 
                                    :disabled="uploading" 
                                    accept=".pfx" 
                                    class="input-file"
                                    @change="pfxFileChanged">
                            <template v-if="!pfx_files">
                                SELECT
                            </template>
                            <template v-else>
                                <span v-for="file in pfx_files" :key="file.name">
                                    {{file.name}} 
                                </span>
                            </template>
                        </span> 
                    </div>
                    <div class="row dropbox justify-content-center align-content-center" @drop="fileDropped" @dragover.prevent @drop.prevent>
                        <div v-if="!uploading">
                            Drop your PFX file here
                        </div>
                        <div v-else>
                            Uploading file...
                        </div>
                    </div>
                    <div class="row font-weight-bold p-0" v-if="results">
                        <h4 class="card-header col-12 text-center text-info">UPLOAD RESULTS</h4>
                    </div>
                    <div class="row" v-for="result in results" :key="result">
                        {{result}}
                    </div>
                    <div class="row font-weight-bold p-0" v-if="error_info">
                        <h4 class="card-header col-12 text-center text-warning">ERROR</h4>
                    </div>
                    <div class="row" v-if="error_info">
                        {{error_info}}
                    </div>
                </form>
            </div>
            <div class="card-footer text-center">
                <div class="row">
                    <a :class="{'col-12' : true, btn: true, 'btn-warning' : !error, 'btn-danger' : error, 'font-weight-bold' : true, }" @click="upload_cert()">
                        <div :class="{'spinner-grow': uploading}">
                            <template v-if="!uploading">
                                INSTALL CERT
                            </template>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { hosturl } from '@/models/hub'
import { defineComponent } from "vue"
import { useRoute } from 'vue-router'

export default defineComponent({
    name: 'DeviceCertUploader',
    data() {
        const route = useRoute();

        return {
            uploading: false,
            error: false,
            error_info: undefined as string | undefined,
            results: undefined as string[] | undefined,
            device_uuid: route.params.uuid as string,
            pfx_password: "",
            pfx_files: undefined as FileList | undefined,
        }
    },
    components: {
    },
    computed: {
    },
    methods: {
        upload_cert() {
            if (this.uploading || !this.pfx_files) {
                return
            }

            this.uploading = true
            this.error = false
            this.error_info = undefined
            this.results = undefined

            const formData = new FormData()
            
            formData.append('file', this.pfx_files[0])
            formData.append('password', this.pfx_password)

            fetch(`${hosturl}/api/network/pfx/${this.device_uuid}`,
                    {
                        method: "POST",
                        body: formData
                    })
            .then(response => {
                if (!response.ok) {
                    throw `STATUS: ${response.status} : ${response.statusText}`
                }

                return response.json()
            })
            .then(info => {
                this.results = info.results

                if (info.error) {
                    throw info.error
                }
            })
            .catch(error => {
                this.error_info = error
                this.error = true
            })
            .finally(()=>{
                this.uploading = false
            })
        },
        pfxFileChanged(e : Event) {
            const target = e.target as HTMLInputElement

            if (target.files) {
                this.pfx_files = target.files
            }
        },
        fileDropped(e : DragEvent) {
            if (!e.dataTransfer?.files.length) return

            this.pfx_files = e.dataTransfer?.files
        },
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .card-margin {
        margin: 8px;
    }

    .dropbox {
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -10px;
        color: dimgray;
        padding: 10px 10px;
        min-height: 200px; /* minimum height */
        position: relative;
        cursor: pointer;
    }    

    input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        font-size: 100px;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        outline: none;
        background: white;
        cursor: inherit;
        display: block;
    }
</style>
