<template>
    <div class="h-100 d-flex flex-row align-self-center">
        <div class="badge device-badge p-2 my-auto"
          :class="{
              'badge-success':good, 
              'badge-warning':warn, 
              'badge-danger':fail,
              'badge-light':(!good&!warn&!fail)
          }">
          <div v-if="state">
            {{state}}
          </div>
          <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'DeviceBadge',
  props: {
    state: String,
    good: Boolean,
    warn: Boolean,
    fail: Boolean,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.device-badge {
  margin: 8px;
  display: inline-block;
  align-items: center;
  align-content: space-around;
}
</style>
