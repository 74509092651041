import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexcharts = _resolveComponent("apexcharts")!

  return (_openBlock(), _createBlock(_component_apexcharts, {
    class: _normalizeClass(_ctx.get_classes),
    width: _ctx.width,
    height: _ctx.height,
    type: "line",
    id: _ctx.id,
    series: _ctx.series,
    options: _ctx.options
  }, null, 8, ["class", "width", "height", "id", "series", "options"]))
}