<template>
        <div class="topnav-navbar topnav-navbar-dark navbar-nav">
            <div class="align-content-center align-content-between d-flex flex-row justify-content-around">
                <div class="d-flex justify-content-start col-auto p-2">
                    <a :href="get_base_url()">
                        <img :src="get_image_url('/virgo.svg')" alt="Virgo" class="virgo-logo">
                    </a>
                    <h6 class="text-info m-1">{{ get_build() }}</h6>
                </div>
                <div class="d-flex w-100">    
                </div>
                <a class="dropdown-toggle arrow-none d-flex justify-content-end p-1" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                    <i class="dripicons-view-apps top-menu-icon text-dark"></i>
                </a>
                <div class="dropdown-menu dropdown-lg p-0 font-16">
                    <div class="scrollable-menu">
                        <router-link to="/" class="dropdown-item notify-item">
                            Home
                        </router-link>
                        <router-link to="/COMET-PRO/devices" class="dropdown-item notify-item" v-if="check_fleet('COMET-PRO')">
                            US Devices
                        </router-link>
                        <router-link to="/COMET-EU/devices" class="dropdown-item notify-item" v-if="check_fleet('COMET-EU')">
                            EU Devices
                        </router-link>
                        <router-link to="/COMET-CA/devices" class="dropdown-item notify-item" v-if="check_fleet('COMET-CA')">
                            CA Devices
                        </router-link>
                        <router-link to="/comet-pool/devices" class="dropdown-item notify-item" v-if="check_fleet('comet-pool')">
                            Comet-POOL Devices
                        </router-link>
                        <template v-if="check_fleet('DEV')">
                            <router-link to="/SLING-DEV/devices" class="dropdown-item notify-item">
                                Sling-DEV Devices
                            </router-link>
                            <router-link to="/Auriga/devices" class="dropdown-item notify-item">
                                Sling Devices
                            </router-link>
                            <router-link to="/COMET-DEV/devices" class="dropdown-item notify-item">
                                Comet-DEV Devices
                            </router-link>
                        </template>
                        <template v-if="check_reports('DEVICES')">
                            <router-link to="/reports/balena/comet/COMET-PRO" class="dropdown-item notify-item" v-if="check_fleet('COMET-PRO')">
                                US Comet Report
                            </router-link>
                            <router-link to="/reports/balena/comet/COMET-EU" class="dropdown-item notify-item" v-if="check_fleet('COMET-EU')">
                                EU Comet Report
                            </router-link>
                            <router-link to="/reports/balena/comet/COMET-CA" class="dropdown-item notify-item" v-if="check_fleet('COMET-CA')">
                                CA Comet Report
                            </router-link>
                        </template>
                        <template v-if="check_reports('CERTS')">
                            <router-link to="/reports/device/certs/COMET-PRO" class="dropdown-item notify-item" v-if="check_fleet('COMET-PRO')">
                                US Device Certs Report
                            </router-link>
                            <router-link to="/reports/device/certs/COMET-EU" class="dropdown-item notify-item" v-if="check_fleet('COMET-EU')">
                                EU Device Certs Report
                            </router-link>
                            <router-link to="/reports/device/certs/COMET-CA" class="dropdown-item notify-item" v-if="check_fleet('COMET-CA')">
                                CA Device Certs Report
                            </router-link>
                            <router-link to="/reports/device/certs/COMET-DEV" class="dropdown-item notify-item" v-if="check_fleet('COMET-DEV')">
                                DEV Device Certs Report
                            </router-link>
                        </template>
                    </div>
                </div>
            </div>
        </div>
</template>

<script lang="ts">
import { access_methods, url_methods } from '@/models/hub'

export default {
    name: 'TopMenu',
    props: {
        msg: String
    },
    methods: { ...access_methods, ...url_methods },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.virgo-logo {
    height: 64px;
}

.top-menu-icon {
    font-size: 48px;
}

.scrollable-menu {
    max-height: calc(100vh - 88px);
    overflow-y: auto;
}
</style>
