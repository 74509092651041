<template>
    <div class="card border border-secondary">
        <div class="card-header">
            <h4 class="text-dark text-center">NETWORK CONFIGURATIONS</h4>
        </div>
        <div class="card-body w-100 text-success">
            <div class="row">
                <div class="col-2">NAME</div>
                <div class="col-1">TYPE</div>
                <div class="col-2">SSID</div>
                <div class="col-2">IP</div>
                <div class="col-2">DNS</div>
            </div>
            <div class="row" v-for="config in configs" :key="config.id">
                <div class="col-2 text-info">{{config.id}}</div>
                <div class="col-1 text-info">{{config.type}}</div>
                <div class="col-2 text-info">{{config.ssid??"--"}}</div>
                <div class="col-2 text-info">{{config.address1??"--"}}</div>
                <div class="col-2 text-info">{{config.dns??"--"}}</div>
            </div>
        </div>
        <div class="card-footer text-center">
            <div class="row">
                <a :class="{'col-12' : true, btn: true, 'btn-warning' : !update_error, 'btn-danger' : update_error, 'font-weight-bold' : true, }" @click="update_connections()">
                    <div :class="{'spinner-grow': updating}">
                        <template v-if="!updating">
                            CHECK
                        </template>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { hosturl } from '@/models/hub'
import { defineComponent } from "vue"

interface ConfigFields {
    id: string
    type: string
    ssid?: string
    address1?: string
    dns?: string
}

export default defineComponent({
    name: 'DeviceConnections',
    props: {
        device_uuid: String,
    },
    data() {
        return {
            updating: false,
            update_error: false,
            errorMessage: "",
            configs: {} as Array<ConfigFields>,
        }
    },
    components: {
    },
    computed: {
    },
    methods: {
        update_connections() {
            if (this.updating) {
                return
            }

            this.updating = true
            this.update_error = false

            fetch(`${hosturl}/api/network/connections/${this.device_uuid}`,
                    {
                        method: "GET",
                    })
            .then(response => {
                if (response.status != 200) {
                    throw `STATUS: ${response.status} - Failed!`
                }

                return response.json()
            })
            .then(info => {
                this.configs = info["configs"]
            })
            .catch(error => {
                this.errorMessage = error
                this.update_error = true
            })
            .finally(()=>{
                this.updating = false
            })
        },
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

