<template>
    <div :style="tableStyle" class="w-100">
        <div :class="{'table-body' : true,  'h-100' : true, 'table-striped' : striped, 'col-12' : true }">
            <slot></slot>
        </div>            
    </div>
</template>

<script>
export default {
    name: 'ScrollArea',
    props: {
        striped: Boolean,
    },
    data() {
        return {
        top: 0,
    }},
    mounted() {
      const { top } = this.$el.getBoundingClientRect();
      this.top = top;
    },
    computed: {
        tableStyle() {
            return `height: ${(window.innerHeight - this.top)}px`
        }
    },
    emits: [
    ],
    components: {
    },
    methods: {
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.table-striped > div:nth-of-type(odd) {
    background: #464f5b;
}

.table-body {
    overflow-x: hidden;
    overflow-y: auto;
}
</style>
