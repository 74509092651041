import VueApexCharts from "vue3-apexcharts";
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import { store } from "./models/hub";

const listeners : { [key : string]: ()=>void } = {}

const app = createApp(App).use(router);

app.use(store)

app.directive('dimensions', {
    created(el : Element, binding) {
        const onResizeCallback = binding.value;

        const eventHandler = () => {
            const rect = el.getBoundingClientRect()

            onResizeCallback(
                rect.left,
                rect.top,
                rect.width,
                rect.height
            );
        }

        el.addEventListener('resize', eventHandler)

        listeners[el.id] = eventHandler

        const rect = el.getBoundingClientRect()
        onResizeCallback(
            rect.left,
            rect.top,
            rect.width,
            rect.height
        );
    },
    // eslint-disable-next-line
    unmounted(el : Element, _binding) {
        if (el.id in listeners) {
            el.removeEventListener('dimensions', listeners[el.id])
            delete listeners[el.id]
        }
    }
});

app.mount('#app');
app.use(VueApexCharts);

