<template>
    <div class="card border border-secondary">
        <div class="card-header">
            <h4 class="text-dark text-center">{{ ethernet?"ETH":"WIFI" }} CONFIGURATION</h4>
        </div>
        <div class="card-body w-100 text-success">
            <div class="row h-100 align-items-center">
                <div class="col-5 text-dark h-100">CONFIGURATION</div>
                <div class="col-7 text-md-right">
                    <a class="btn btn-success dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                        <template v-if="net_config.name">
                            {{net_config.name}}
                        </template>
                        <template v-else>
                            SELECT CONFIG
                        </template>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-lg menu-fix">
                        <template v-if="ethernet">
                            <li class="dropdown-item badge-light" v-for="config in eth_configs" :key="config.name" @click="set_config(config)">
                                {{config.name}}
                            </li>
                        </template>
                        <template v-else>
                            <li class="dropdown-item badge-light" v-for="config in wifi_configs" :key="config.name" @click="set_config(config)">
                                {{config.name}}
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
            <template v-if="net_config">
                <div v-for="field in net_config.fields" :key="field">
                    <template v-if="field.includes('PASSWORD')">
                        <div class="row h-100 align-items-center p-1">
                            <div :class="{'col-5' : true, 'text-dark' : fields[field] == password[field], 'text-danger' : fields[field] != password[field], 'h-100': true, 'p-1' : true }">{{field}}</div>
                            <input type="password" class="form-control col-7 p-1 text-right" name="{{field}}" v-model="fields[field]">
                        </div>
                        <div class="row h-100 align-items-center p-1">
                            <div :class="{'col-5' : true, 'text-dark' : fields[field] == password[field], 'text-danger' : fields[field] != password[field], 'h-100': true, 'p-1' : true }">{{field}}</div>
                            <input type="password" class="form-control col-7 p-1 text-right" name="{{field}}-TWO" v-model="password[field]">
                        </div>
                    </template>
                    <template v-else>
                        <div class="row h-100 align-items-center p-1">
                            <div class="col-5 text-dark h-100">{{field}}</div>
                            <input type="text" class="form-control col-7 text-right" name="{{field}}" v-model="fields[field]">
                        </div>
                    </template>
                </div>
            </template>
        </div>
        <div class="card-footer text-center">
            <div class="row">
                <a :class="{'col-12' : true, btn: true, 'btn-warning' : !update_error, 'btn-danger' : update_error, 'font-weight-bold' : true, }" @click="update_config()">
                    <div :class="{'spinner-grow': updating}">
                        <template v-if="!updating">
                            UPDATE
                        </template>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { NetworkConfig, QueryResponse } from '@/models/Devices';
import { hosturl } from '@/models/hub'
import { defineComponent } from "vue"

interface FieldMap {
    [key: string] : string
}

export default defineComponent({
    name: 'DeviceNetworkConfig',
    props: {
        ethernet: Boolean,
        device_uuid: String,
    },
    data() {
        return {
            net_config: {} as NetworkConfig,
            wifi_configs: Array<NetworkConfig>(),
            eth_configs: Array<NetworkConfig>(),
            updating: false,
            update_error: false,
            querying: false,
            query_error: false,
            ready: false,
            loading: false,
            error: false,
            errorMessage: "",
            pfx_password: "",
            fields: {} as FieldMap,
            password: {} as FieldMap,
            query: {} as QueryResponse,
        }
    },
    components: {
    },
    computed: {
    },
    methods: {
        get_net_configs() {
            this.ready = false
            this.loading = true

            fetch(`${hosturl}/api/network/configs`)
            .then(response => {
                if (response.status != 200) {
                    throw `STATUS: ${response.status} - Failed!`
                }

                return response.json()
            })
            .then(configs=> {
                this.wifi_configs = configs["wifi"]
                this.eth_configs = configs["eth"]
                this.error = false
                this.ready = true
            })
            .catch(error => {
                this.errorMessage = error
                this.error = true
            })
            .finally(()=>{
                this.loading = false
            })
        },
        set_config(config : NetworkConfig) {
            this.net_config = config
        },
        update_config() {
            if (this.updating) {
                return
            }

            this.updating = true
            this.update_error = false

            fetch(`${hosturl}/api/network/config/${this.device_uuid}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type" : "application/json"
                        },
                        body: JSON.stringify({
                            "config" : this.net_config?.name,
                            "fields" : this.fields
                        })
                    })
            .then(response => {
                if (response.status != 200) {
                    throw `STATUS: ${response.status} - Failed!`
                }

                return response.json()
            })
            .then(info => {
                info["results"]
            })
            .catch(error => {
                this.errorMessage = error
                this.update_error = true
            })
            .finally(()=>{
                this.updating = false
            })
        },
    },
    mounted() {
        this.get_net_configs()
    }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .card-margin {
        margin: 8px;
    }

    .menu-fix {
        width: 80%;
        height: 80%;
        background: #464f5b;
    }
</style>

