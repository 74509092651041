<template>
<div class="vh-100 d-flex align-items-center">
    <div class="container">
      <form class="w-100" @submit.prevent="beginSSOLogin">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="card border border-secondary">
                    <div class="card-body p-1 m-1">
                        <div class="text-center m-2">
                            <img :src="get_image_url('/virgo-dot.svg')" width="128" height="128" alt="Virgo SVS Logo"/>
                        </div>
                        <div class="d-flex justify-content-center">
                            <input
                                type="email"
                                class="form-control w-75"
                                id="sso_email"
                                placeholder="Enter your E-mail"
                                v-model="ssoEmail"
                                style="width: 60%;"
                            />
                        </div>
                        <div class="text-center mt-3">
                            <button :disabled="ssoSubmission" class="btn btn-success w-75" type="submit">SSO LOGIN</button>
                        </div>
                    </div>
                    <div class="card-footer text-center">
                      <div class=" text-muted">
                        <div>
                          Access to this site is limited to authorized organizations only!
                        </div>
                        <div>
                          Virgo Surgical Video Solutions logs all interactions and any unauthorized attempts will be supplied to the proper authorities.
                        </div>
                      </div>
                        <!-- Error message here -->
                    </div>
                </div>
            </div>
        </div>
      </form>
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import {hosturl, url_methods} from "@/models/hub";

export default defineComponent({
  name: 'SSOLogin',
  props: {
  },
  data() {
    return {
      ssoEmail: "",
      ssoSubmission: false,
      error: "FAILED"
    }
  },
  components: {
  },
  methods: {
    ...url_methods,
    async beginSSOLogin() {
        this.ssoSubmission = true;
        
        try{
          const result = await fetch(`${hosturl}/saml/login`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                "email": this.ssoEmail,
              })
            })

          if (result.status != 200) {
            throw `STATUS: ${result.status} - Failed!`
          }
          
          const info = await result.json()

          window.location.href = info["redirect_url"];
        } catch (error) {
          this.error = error as string;
        } finally {
          this.ssoSubmission = false
        }
    },
  },
  computed: {
  },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .card-margin {
    margin: 8px;
    min-width: 240px;
  }
</style>
