<template>
    <div class="card border border-secondary m-1 card-margin">
        <div class="card-header">
            <h4 class="text-dark text-center">DEVICE CERT</h4>
        </div>
        <div class="card-body">
            <template v-if="cert_info">
                <div class="w-100 text-success">
                    <div class="row">
                        <p class="col-4 text-dark">ISSUER</p>
                        <p class="col-8 text-truncate">{{current_info.issuer}}</p>
                    </div>
                    <div class="row">
                        <p class="col-4 text-dark">SUBJECT</p>
                        <p class="col-8 text-truncate">{{current_info.subject}}</p>
                    </div>
                    <div class="row">
                        <p class="col-4 text-dark">BEFORE</p>
                        <p class="col-8 text-truncate">{{current_info.not_valid_before}}</p>
                    </div>
                    <div class="row">
                        <p class="col-4 text-dark">AFTER</p>
                        <p class="col-8 text-truncate">{{current_info.not_valid_after}}</p>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="row">
                    <h3 class="text-warning text-center w-100">CERT INFO NOT FOUND</h3>
                </div>
            </template>
        </div>
        <div class="card-footer text-center">
            <h4 :style="get_style">{{get_status}}</h4>
            <template v-if="current_info?.should_renew===true">
                <div class="row">
                    <a :class="{'col-12' : true, btn: true, 'btn-warning' : !renew_error, 'btn-danger' : renew_error, 'font-weight-bold' : true, }" @click="renew_device()">
                        <div :class="{'spinner-grow': renewing}">
                            <template v-if="!renewing">
                                RENEW
                            </template>
                        </div>
                    </a>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { hosturl } from '@/models/hub'
import { defineComponent } from "vue"
import { NotificationColors } from "@/models/UI"
import { CertInfo } from "@/models/Devices"
import { useRoute } from 'vue-router'

export default defineComponent({
    name: 'DeviceCertInfo',
    props: {
        cert_info: Object as () => CertInfo,
    },
    data() {
        const route = useRoute();

        return {
            device_uuid: route.params.uuid as string,
            current_info: this.cert_info as CertInfo,
            renewing: false,
            renew_error: false,
        }
    },
    components: {
    },
    computed: {
        get_status() : string {
            if (!this.cert_info) {
                return "NA"
            }

            const before = Date.parse(this.current_info.not_valid_before)
            const after = Date.parse(this.current_info.not_valid_after)
            const now = new Date()
            const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000

            if (utc < before) {
                return "INVALID"
            }

            if (utc > after) {
                return "EXPIRED"
            }

            return "VALID"
        },        
        get_style() : string {
            if (!this.cert_info) {
                return `color: ${NotificationColors.WARN}`
            }

            const before = Date.parse(this.cert_info.not_valid_before)
            const after = Date.parse(this.cert_info.not_valid_after)
            const now = new Date()
            const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000

            if (utc < before ||
                utc > after) {
                return `color: ${NotificationColors.FAIL}`
            }

            return `color: ${NotificationColors.PASS}`
        },        
    },
    methods: {
        renew_device() {
            this.renew_error = false
            this.renewing = true
            fetch(`${hosturl}/api/device/${this.device_uuid}/renew`)
            .then(response => {
                this.renewing = false
                if (response.status != 200) {
                    this.renew_error = true
                }
                return response.json()
            })
            .then(renewal=>{
                this.current_info = renewal["info"]["info"]
            })
        },
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .card-margin {
        margin: 8px;
        min-width: 480px;
    }
</style>
