<template>
    <div class="col-12">
        <apexcharts :width="width" :height="height" :type="type" :id="id" :options="options" :series="options.series" :data-colors="options.data_color" />
    </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  name: 'Gauge',
  props: {
      width: {
          type: String,
          default: ()=>"200px"
      },
      height: {
          type: String,
          default: ()=>"200px"
      },
      type: String,
      label: String,
      color: String,
      reading: Number,
      id: String,
  },
  components: {
      apexcharts: VueApexCharts
  },
  computed: {
    options: function () { 
        return {
            series: [ this.reading, ],
            labels: [ this.label, ],
            colors: [ this.color, ],
        };
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
