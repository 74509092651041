<template>
  <div v-if="summaryReady" class="col-12">
    <scroll-area>
      <h2>{{summary.time}}</h2>
      <div class="flex-row">
        <div class="col-12">
          <MapBox :zoom="2.5" height="480px" :locations="locations"/>
        </div>
      </div>
      <div>
        <div class="flex-row">
          <div class="col-12">
            <div class="card-deck-wrapper">
              <div class="card-deck">
                <device-card
                    title="US DEVICES"
                    button-label="DEVICES"
                    fleet="COMET-PRO"
                    button-link="COMET-PRO/devices"
                    @locations="(devices) => locations = [...locations, ...devices]"
                    v-if="check_fleet('COMET-PRO')"
                />
                <device-card
                    title="CA DEVICES"
                    button-label="DEVICES"
                    fleet="COMET-CA"
                    button-link="COMET-CA/devices"
                    @locations="(devices) => locations = [...locations, ...devices]"
                    v-if="check_fleet('COMET-CA')"
                />
                <device-card
                    title="EU DEVICES"
                    button-label="DEVICES"
                    fleet="COMET-EU"
                    button-link="COMET-EU/devices"
                    @locations="(devices) => locations = [...locations, ...devices]"
                    v-if="check_fleet('COMET-EU')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex-row" v-if="check_fleet('DEV')">
          <div class="col-12">
            <div class="card-deck-wrapper">
              <div class="card-deck">
                <device-card
                    title="SLING DEVICES"
                    button-label="DEVICES"
                    fleet="Auriga"
                    button-link="Auriga/devices"
                    @locations="(devices) => locations = [...locations, ...devices]"
                />
                <device-card
                    title="COMET-DEV DEVICES"
                    button-label="DEVICES"
                    fleet="COMET-DEV"
                    button-link="COMET-DEV/devices"
                    @locations="(devices) => locations = [...locations, ...devices]"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex-row">
          <div class="col-12">
            <div class="card-deck-wrapper">
                <div class="card-deck">
                  <device-proxy :us="check_fleet('COMET-PRO')" :eu="check_fleet('COMET-EU')" :ca="check_fleet('COMET-CA')" :qa="check_fleet('DEV')" v-if="check_services('PROXY')" />
                  <portal-status :us="check_fleet('COMET-PRO')" :eu="check_fleet('COMET-EU')" :ca="check_fleet('COMET-CA')" :qa="check_fleet('DEV')" v-if="check_services('PORTAL')" />
                </div>
            </div>
          </div>
        </div>
        <div class="flex-row" v-if="check_logging('CLOUD')">
          <div class="card-header">
            <h3 class="text-success">US SERVICES</h3>
          </div>
          <div class="card-body">
            <div class="card-deck-wrapper">
              <div class="card-deck">
                <log-summary service="ariane" />
                <log-summary service="crohn" />
                <log-summary service="clipper" />
                <log-summary service="auto-procedure" />
                <log-summary service="pip" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </scroll-area>
  </div>
  <div v-else class="content-page">
    <div v-if="loadingSummary" class="text-center">
      <h3>Loading Summary...</h3>
      <div class="spinner-grow avatar-lg text-info" style="padding: 10%; margin: 10%;" role="status"></div> 
    </div>
    <div v-if="error">
      <h2>{{errorMessage}}!</h2>
    </div> 
  </div>
</template>

<script lang="ts">
// @ is an alias to /src
import MapBox from '@/components/Map.vue'
import DeviceProxy from '@/components/DeviceProxy.vue'
import LogSummary from '@/components/LogSummary.vue'
import PortalStatus from '@/components/PortalStatus.vue'
import ScrollArea from '@/components/ScrollArea.vue'
import DeviceCard from '@/components/DeviceCard.vue'
import { access_methods } from '@/models/hub';

export default {
  name: 'Home',
  components: {
    MapBox,
    DeviceProxy,
    LogSummary,
    PortalStatus,
    ScrollArea,
    DeviceCard,
  },
  data() {
    return {
      summaryReady: true,
      loadingSummary: false,
      errorMessage: '',
      error: false,
      summary: {},
      locations: [],
      service_keys: ["ERRORS", "WARNINGS", "TOTAL"]
    }
  },
  methods: {
    ...access_methods,
  }
}
</script>
