import { ActionContext, createStore, useStore } from "vuex"

export const imageBasePath = process.env.VUE_APP_PUBLIC_PATH?(process.env.VUE_APP_PUBLIC_PATH+"/images").replace("//","/"):"/images";

export const baseApiUrl = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:8000' 
    : `${window.location.origin}${process.env.VUE_APP_PUBLIC_PATH}`;

export const hosturl = baseApiUrl.endsWith('/') 
    ? baseApiUrl.slice(0, -1) // Remove trailing slash to prevent double slashes in API calls
    : baseApiUrl;

export interface AccessInfo {
    account: {
        email: string
        expiration: number
        logged_in: boolean
    }
    fleets: Array<string>
    projects: Array<string>
    reports: Array<string>
    logging: Array<string>
    services: Array<string>
}

export interface Config {
    hosturl: string
    build: string
    access: AccessInfo
}

export const store = createStore({
    state: {
        config: {
            hosturl: hosturl,
            build: process.env.VUE_APP_BUILD_VERSION??"NA.NA.NA",
            access: {} as AccessInfo
        } as Config
    },
    mutations: {
        setConfig(state: { config: Config}, config : Config) {
            state.config = config;
        }
    },
    actions: {
        async fetchConfig( action : ActionContext<{ config: Config}, {config: Config}>) {
        await fetch(`${hosturl}/api/access`)
                .then(response => {
                    return response.json()
                })
                .then(access_info => {
                    action.commit('setConfig', {
                        hosturl: hosturl,
                        build: process.env.VUE_APP_BUILD_VERSION??"NA.NA.NA",
                        access: access_info as AccessInfo
                    })
                })
        }
    }
});

export const url_methods = {
    get_portal_url(fleet : string, uuid : string) : string {
        const host_parts = location.hostname.split(".").reverse()
        let project = ""
        let domain = "virgosvs.com"

        if (host_parts.includes("eu") || fleet === "COMET-EU")  {
            project = "eu"
        }

        if (host_parts.includes("ca") || fleet === "COMET-CA") {
            project = "ca"
        }

        if (host_parts.includes("virgo-qa") || fleet === "COMET-DEV") {
            domain = "virgo-qa.com"
        }

        if (fleet === "COMET-PRO")  {
            project = ""
            domain = "virgosvs.com"
        }

        if (project !== "") {
            return `https://portal.${project}.${domain}/admin/devices/device/?provisioning_id=${uuid}`
        }

        return `https://portal.${domain}/admin/devices/device/?provisioning_id=${uuid}`
    },
    get_device_url(fleet: string, uuid : string) : string {
        return `${this.get_base_url()}/device/${fleet}/${uuid}`
    },
    get_image_url(image: string) : string {
        return `${imageBasePath}${image}`
    },
    get_base_url(): string {
        const baseUrl = process.env.VUE_APP_PUBLIC_PATH;
        if (!baseUrl) return '';
        return baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
    }
}

export const access_methods = {
    access() : AccessInfo {
        return useStore().state.config.access
    },
    get_build() : string {
        return useStore().state.config.build
    },
    check_fleet(fleet: string) : boolean {
        const access = this.access()

        if (access == undefined ||
            access.fleets == undefined ||
            access.fleets.includes("none")) {
            return false
        }

        return access.fleets.includes("all") || access.fleets.includes(fleet.toLowerCase())
    },
    check_reports(report: string) : boolean {
        const access = this.access()

        if (access == undefined ||
            access.reports == undefined ||
            access.reports.includes("none")) {
            return false
        }

        return access.reports.includes("all") || access.reports.includes(report.toLowerCase())
    },
    check_logging(logging: string) : boolean {
        const access = this.access()

        if (access == undefined ||
            access.logging == undefined ||
            access.logging.includes("none")) {
            return false
        }

        return access.logging.includes("all") || access.logging.includes(logging.toLowerCase())
    },
    check_services(service: string) : boolean {
        const access = this.access()

        if (access == undefined ||
            access.services == undefined ||
            access.services.includes("none")) {
            return false
        }

        return access.services.includes("all") || access.services.includes(service.toLowerCase())
    }
}