<template>
  <div>
    <TopMenu v-if="config.access.account.logged_in === true" />
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script lang="ts">
// @ is an alias to /src
import TopMenu from '@/components/TopMenu.vue'
import { mapState } from 'vuex'
import { store } from '@/models/hub'
import { defineComponent } from "vue"

export default  defineComponent({
  name: 'App',
  components: {
    TopMenu,
  },
  data() {
    return {
      lid_timer: -1
    }
  },
  computed: { ...mapState(['config']) },
  methods: {
    loggedInDeadman() {
      if (this.config.access.account.logged_in !== true) {
        return
      }

      store.dispatch("fetchConfig")
    }
  },
  mounted() {
    store.dispatch("fetchConfig")

    this.lid_timer = setInterval(this.loggedInDeadman, 60 * 1000)
    const scripts = [
      //Eliminates a load error -- not needed?
      //"js/app.min.js",
      "js/vendor.min.js",
    ]

    scripts.forEach(entry => {
      const script = document.createElement('script')

      script.setAttribute('type','text/javascript')
      script.setAttribute('src', entry)
      document.body.appendChild(script)
    });
  },
  unmounted() {
    clearInterval(this.lid_timer)
  }
})
</script>
