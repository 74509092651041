/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {

  register(`${process.env.VUE_APP_PUBLIC_PATH}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
      
      let refreshing = false

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing) return
      
        console.log('refreshing.')
        refreshing = true
        window.location.reload()
      })
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    async updated () {
      console.log('New content is available; please refresh.')
      const registration =  await navigator.serviceWorker.getRegistration()

      console.log('state has changed.')
      
      if (registration?.waiting) {
          if (registration.waiting.state != "activated") {
            console.log('skip waiting.')
            registration.waiting.postMessage({ type: 'SKIP_WAITING' })
          }
      }

    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
