<template>
    <div class="content-page">
        <div v-if="!check_fleet(fleet)">
            <h3 class="text-warning">FLEET NOT AVAILABLE...</h3>
        </div>
        <template v-else>
            <div v-if="loading" class="text-center">
                <h3>{{reportMessage}}</h3>
                <div class="spinner-grow avatar-lg text-info" style="padding: 10%; margin: 10%;" role="status">
                </div> 
            </div>
            <div v-if="error">
                <h2 class="text-warning">ERROR: {{errorMessage}}</h2>
            </div> 
            <div v-if="ready" class="content">
                <h2 class="text-info">DEVICE CERT REPORT FOR {{ fleet }}</h2>
                <h3>{{reportTime}}</h3>
                <main class="report-container">
                    <div class="w-100">
                        <template v-if="report.errors">
                            <div class="row">
                                <button v-on:click="csv_errors" class="col-auto btn btn-outline-primary btn-rounded download"><i class="mdi mdi-download"></i></button>
                                <h3 class="text-warning col-auto">ERRORS [{{report.errors.length}}]</h3>
                            </div>
                            <div class="row report-row">
                                <h3 class="col-1">STATUS</h3>
                                <h3 class="col-2">NAME</h3>
                                <h3 class="col-3">UUID</h3>
                                <h3 class="col-4">ERROR</h3>
                                <h3 class="col-2"></h3>
                            </div>
                            <div v-for="error in report.errors" :key="error.device.uuid">
                                <div class="row report-row">
                                    <div class="col-1"><DeviceBadge :good="error.device.online" :fail="!error.device.online" :state="error.device.online?'ONLINE':'OFFLINE'" /></div>
                                    <div class="col-2 text-nowrap text-truncate">{{error.device.name}}</div>
                                    <div class="col-3 text-nowrap text-truncate">{{error.device.uuid}}</div>
                                    <div class="col-4">{{error.error}}</div>
                                    <div class="col-2 d-flex-reverse align-items-center">
                                        <template v-if="check_services('portal-admin')">
                                            <a class="btn btn-info" target="_blank" :href="get_portal_url(fleet, error.device.uuid)">
                                                <div class="box">
                                                    <img :src="get_image_url('/portal-196.png')" alt="Virgo Admin Portal" />
                                                </div>
                                            </a>
                                        </template>
                                        <a class="btn btn-info m-1" target="_blank" :href="get_device_url(error.device.uuid)">
                                            <div class="box">
                                                <img :src="get_image_url('/virgo-dot.svg')" alt="Virgo" />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="row">
                            <button v-on:click="csv_devices" class="col-auto btn btn-outline-primary btn-rounded download"><i class="mdi mdi-download"></i></button>
                            <h3 class="text-info col-auto">DEVICES [{{report.devices.length}}]</h3>
                        </div>
                        <div class="row report-row">
                            <h3 class="col-1">STATUS</h3>
                            <h3 class="col-2">NAME</h3>
                            <h3 class="col-3">UUID</h3>
                            <h3 class="col-3">EXPIRES</h3>
                            <h3 class="col-3"></h3>
                        </div>
                        <div v-for="device in devices" :key="device.device.uuid">
                            <div class="row report-row"> 
                                <div class="col-1">
                                    <DeviceBadge :good="device.device.online" :fail="!device.device.online" :state="device.device.online?'ONLINE':'OFFLINE'" />
                                </div>
                                <div class="col-2 text-nowrap text-truncate">{{device.device.name}}</div>
                                <div class="col-3 text-nowrap text-truncate">{{device.device.uuid}}</div>
                                <div class="col-3">{{device.cert_info.not_valid_after}}</div>
                                <div class="col-3 d-flex-reverse align-items-center">
                                    <template v-if="check_services('portal-admin')">
                                            <a class="btn btn-info" target="_blank" :href="get_portal_url(fleet, device.device.uuid)">
                                                <div class="box">
                                                    <img :src="get_image_url('/portal-196.png')" alt="Virgo Admin Portal" />
                                                </div>
                                            </a>
                                    </template>
                                    <template v-if="check_services('balena')">
                                        <a class="btn btn-info m-1" target="_blank" :href="device.device.url">
                                            <div class="box">
                                                <img :src="get_image_url('/balena_logo.png')" alt="Balena.io" />
                                            </div>
                                        </a>
                                    </template>
                                    <a class="btn btn-info m-1" target="_blank" :href="get_device_url(device.device.uuid)">
                                        <div class="box">
                                            <img :src="get_image_url('/virgo-dot.svg')" alt="Virgo" />
                                        </div>
                                    </a>
                                    <template v-if="device.cert_info?.should_renew===true">
                                        <a :class="{'m-1' : true, 'btn': true, 'btn-warning' : !device.renew_error, 'btn-danger' : device.renew_error, 'font-weight-bold' : true, }" @click="renew_device(device)">
                                            <div :class="{'spinner-grow': device.renewing, 'box': device.renewing} ">
                                                <template v-if="!device.renewing">
                                                    RENEW
                                                </template>
                                            </div>
                                        </a>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
    import { hosturl, access_methods, url_methods } from '@/models/hub'
    import { CertInfo } from '@/models/Devices'
    import { useRoute } from 'vue-router'
    import { defineComponent } from 'vue'
    import DeviceBadge from '@/components/DeviceBadge.vue'

    interface DeviceStatus {
        device: {
            name: string
            uuid: string
            online: boolean
            url: string
        }
        cert_info: CertInfo
        renewing: boolean
        renew_error: boolean
    }

    interface Report {
        devices: Array<DeviceStatus>

        errors: Array<{
            device: {
                name: string
                uuid: string
                online: boolean
            }
            error: string
        }>
    }

    export default defineComponent({
        name: 'CertReport',
        props: {
        },
        components: {
            DeviceBadge,
        },
        data() {
            const route = useRoute();

            return {
                reportTime: '',
                loading: false,
                ready: false,
                error: false,
                errorMessage: 'NA',
                reportMessage: 'Standby...',
                report: {} as Report,
                fleet: route.params.fleet as string,
            }
        },
        computed: {
            devices: function () {
                const devices : Array<DeviceStatus> = [...this.report.devices]

                devices.sort((a,b)=>{
                    return a.cert_info.not_valid_after.localeCompare(b.cert_info.not_valid_after)
                })

                return devices
            }
        },
        methods: {
            ...url_methods,
            ...access_methods,
            renew_device(device : DeviceStatus) {
                device.renew_error = false
                device.renewing = true
                fetch(`${hosturl}/api/device/${device.device.uuid}/renew`)
                .then(response => {
                    device.renewing = false
                    if (response.status != 200) {
                        device.renew_error = true
                    }
                    return response.json()
                })
                .then(renewal=>{
                    if (!device.renew_error) {
                        device.cert_info = renewal["info"]["info"]
                    }
                })
            },
            get_device_url(uuid : string) {
                return `${this.get_base_url()}/device/${uuid}`
            },
            async refresh_report() {
                await this.generate_report()
            },
            async generate_report() {
                this.ready = false
                this.loading = true
                this.reportMessage = "Checking Certs..."
                let status = 202
                while (status == 202)
                {
                    await fetch(`${hosturl}/api/device/cert_report/${this.fleet}`)
                    .then(response => {
                        if (response.status > 202)
                        {
                            throw `STATUS: ${response.status} - Failed!`
                        }
                        status = response.status
                        return response.json()
                    })
                    .then(report => {
                        this.reportMessage = report.status

                        if (status == 200)
                        {
                            this.report = report
                            this.reportTime = Date().toString()
                            this.ready = true
                            this.loading = false
                        }
                    })
                    .catch(error => {
                        this.report = {} as Report
                        this.errorMessage = error
                        this.error = true
                        this.loading = false
                    })

                    await new Promise(f => setTimeout(f, 1000));
                }
            },
            csv_errors() {
                let csv = "data:text/csv;charset=utf-8,"
                csv += [
                    [...Object.keys(this.report.errors[0].device), "error"].join(","),
                    ...this.report.errors.map(error => [...Object.values(error.device),error.error].join(","))
                ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");
                const encoded_csv =  encodeURI(csv)

                const link = document.createElement("a");

                link.setAttribute("href", encoded_csv);
                link.setAttribute("download", "cert_errors.csv");
                link.click();
            },
            csv_devices() {
                if (this.report === undefined || 
                    this.report.devices === undefined ||
                    this.report.devices.length === 0) {
                    return "#"
                }

                let csv = "data:text/csv;charset=utf-8,"
                csv += [
                    [...Object.keys(this.report.devices[0].device),Object.keys(this.report.devices[0].cert_info)].join(","),
                    ...this.report.devices.map(device => [...Object.values(device.device),Object.values(device.cert_info)].join(","))
                ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "")
                const encoded_csv =  encodeURI(csv)

                const link = document.createElement("a");
                    link.setAttribute("href", encoded_csv);
                    link.setAttribute("download", "device_certs.csv");
                    link.click();
            }
        },
        async mounted() {
            await this.generate_report()
        }
    })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .report-container {
        height: 85vh;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .download {
        width: 32px;
        height: 32px;
        margin: 4px 16px;
        padding: 0px;
    }

    .download i {
        margin: 4px;
        padding: 0px;
    }

    .report-row {
        margin: 4px;
        min-height: 12px;
        display: flex;
        align-items: center;
        align-content: space-around;
    }

    .box {
        width: 24px;
        height: 24px;
    }

    img {
        width: 100%;
        height: 100%;
    }

    .device-links {
        width: 20%;
    }
</style>
