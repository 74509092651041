<template>
    <div class="card border border-secondary m-1">
        <div class="card-header">
            <h4 class="text-success text-center">DEVICE PROXY</h4>
        </div>
        <div class="card-body col">
            <template v-if="!ready">
                <div class="row">
                    <div class="spinner-grow avatar-lg text-info col-4 loading-spinner" role="status">
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="row" v-for="proxy in proxies" :key="proxy.region">
                    <div class="col-1">
                        <h4>{{ proxy.region }}</h4>
                    </div>
                    <div class="col-3">
                        <h4 :style="get_style(proxy)">{{get_status(proxy)}}</h4>
                    </div>
                    <div v-if="proxy.error" class="text-warning col-8">
                        <h4 style="overflow: hidden;white-space: nowrap;width: 100%;"> {{ proxy.error.err }} </h4>
                    </div>
                    <div v-if="proxy.result" class="col-8">
                        <h4 class="text-info" style="overflow: hidden;white-space: nowrap;width: 100%;">{{ proxy.response.join(" ") }}</h4>
                    </div>            
                </div>
            </template>
        </div>
        <div class="card-footer text-center">
            <h4 :style="get__service_style">{{get_service_status}}</h4>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { NotificationColors } from "@/models/UI"
import { hosturl } from "@/models/hub"

interface ProxyInfo {
    region: string
    online : boolean
    result?: number
    response: string[]
    error?: {
        returncode: number
        err: string
        stdout: string
    }
}

export default defineComponent({
    name: 'DeviceProxy',
    props: {
        qa: {
            type: Boolean,
            required: true
        },
        eu: {
            type: Boolean,
            required: true
        },
        us: {
            type: Boolean,
            required: true
        },
        ca: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            ready: false,
            error: '',
            proxies: Array<ProxyInfo>(),
        }
    },
    components: {
    },
    computed: {
        get_service_status() : string {
            for (const proxy of this.proxies) {
                if (proxy === undefined) {
                    return "NA"
                }

                if (proxy.online == false) {
                    return "OFFLINE"
                }
            }

            return `ONLINE`
        },        
        get__service_style() : string {
            for (const proxy of this.proxies) {
                if (proxy.online === undefined) {
                    return `color: ${NotificationColors.DISABLED}`
                }

                if (proxy.online == false) {
                    return `color: ${NotificationColors.FAIL}`
                }
            }

            return `color: ${NotificationColors.PASS}`
        },      
    },
    methods: { 
        async check_proxy() {
            this.ready = false

            this.proxies = Array<ProxyInfo>()

            if (this.qa)
                this.proxies.push({ region: "QA" } as ProxyInfo)

            if (this.us)
                this.proxies.push({ region: "US" } as ProxyInfo)

            if (this.eu)
                this.proxies.push({ region: "EU" } as ProxyInfo)

            if (this.ca)
                this.proxies.push({ region: "CA" } as ProxyInfo)

            for (const proxy of this.proxies) {
                await fetch(`${hosturl}/api/proxy/check/${proxy.region}`)
                    .then(response => {
                        return response.json()
                    })
                    .then(proxy_response => {
                        Object.assign(proxy, proxy_response)
                    })
                    .catch(error => {
                        proxy.error = {
                            returncode: -1,
                            err: error,
                            stdout: "exception!"
                        }
                    })
            }
            this.ready = true
        },
        get_status(proxy : ProxyInfo) : string {
            if (proxy === undefined ||
                proxy.result === undefined) {
                return "NA"
            }

            if (proxy.online == false) {
                return "OFFLINE"
            }

            return `ONLINE [${proxy.result}]`
        },        
        get_style(proxy : ProxyInfo) : string {
            if (proxy.online === undefined) {
                return `color: ${NotificationColors.DISABLED}`
            }

            if (proxy.online == false) {
                return `color: ${NotificationColors.FAIL}`
            }

            return `color: ${NotificationColors.PASS}`
        },      
    },
    async mounted() {
        await this.check_proxy()
    }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .card-margin {
        margin: 8px;
    }

    .loading-spinner {
        margin: auto;
        width: 150px;
        height: 150px;
    }
</style>
