<template>
  <div class="card border border-secondary card-margin">
    <div class="card-header">
      <h3 class="text-success">{{ title }}</h3>
    </div>
    <template v-if="summaryReady">
      <div class="card-body">
        <div v-for="key in deviceKeys" class="w-auto" :key="key">
          <div class="row">
            <div class="col-8">
              <h4 class="text-info">{{key}}</h4>
            </div>
            <div class="col-4 text-right">
              <h4 class="text-info">{{(fleet_devices[key])}}</h4>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="loadingSummary" class="card-body">
        <div class="row">
            <div class="col-5"></div>
            <div class="spinner-grow avatar-lg text-info col-auto" role="status"></div> 
            <div class="col-5"></div>
        </div>
      </div>
      <div v-if="error" class="card-body">
        <h2>{{errorMessage}}!</h2>
      </div>
    </template>
    <div class="card footer-links">
        <div class="row m-1">
          <a :href="buttonLink" class="btn btn-outline-primary w-100">{{ buttonLabel }}</a>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { hosturl } from '@/models/hub'
import { defineComponent } from "vue"
import {DeviceAvailabilityInfo} from "@/models/Devices";

export default defineComponent({
  name: 'DeviceCard',
  emits: ["locations"],
  props: {
    title: String,
    buttonLabel: String,
    buttonLink: String,
    devices: Object as () => DeviceAvailabilityInfo,
    fleet: String,
  },
  data() {
    return {
      summaryReady: false,
      loadingSummary: false,
      error: false,
      errorMessage: String,
      fleet_devices: this.devices ?? {} as DeviceAvailabilityInfo,
      locations: []
    }
  },
  computed: {
    deviceKeys() {
      return Object.keys(this.fleet_devices)
    }
  },
  mounted() {
    this.get_fleet()
  },
  methods: {
        async get_fleet() {
            if (this.fleet == null) {
              this.loadingSummary = false
              this.summaryReady = true
              return
            }

            this.summaryReady = false
            this.loadingSummary = true
            await fetch(`${hosturl}/api/devices/summary/${this.fleet}`)
            .then(response => {
                if (response.status != 200)
                {
                  throw `STATUS: ${response.status} - Failed!`
                }

                return response.json()
            })
            .then(devices => {
                this.fleet_devices = devices.devices
                this.$emit("locations", devices.locations)
                this.summaryReady = true
            })
            .catch(error => {
                this.locations = []
                this.errorMessage = error
                this.error = true
            })
            .finally(()=>{
                this.loadingSummary = false
            })
        },
  },
});
</script>

<style scoped>
  .card-margin {
    margin: 8px;
    min-width: 240px;
  }
</style>