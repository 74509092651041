<template>
  <div class="content">
    <div v-if="loadingReport" class="text-center">
      <h3>Checking Balena...</h3>
      <div class="spinner-grow avatar-lg text-info" style="padding: 10%; margin: 10%;" role="status"></div> 
    </div>
    <div v-if="reportError">
      <h2 class="text-warning">ERROR: {{errorMessage}}</h2>
    </div> 
    <div v-if="reportReady" class="report-container">
      <h2 class="text-info">SLING FAILURE REPORT [{{reportTime}}]</h2>
      <FailureTable :report="report" :sling="true" />
    </div> 
  </div>
</template>

<script>
  import { hosturl } from '@/models/hub.ts'
  import FailureTable from '@/components/FailureTable.vue'

  export default {
    name: 'SlingBalenaReport',
    props: {
    },
    components: {
      FailureTable,
    },
    data: ()=>{
      return { 
        loadingReport: false,
        reportReady: false,
        reportError: false,
        errorMessage: 'NA',
        report: [],
      }
    },
    methods: {
        async refresh_report() {
          await this.generate_report()
        },
        async generate_report() {
          this.reportReady = false
          this.loadingReport = true
          await fetch(`${hosturl}/api/sling/failures`)
            .then(response => {
              if (response.status != 200)
              {
                throw `STATUS: ${response.status} - Failed!`
              }

              return response.json()
            })
            .then(report => {
              this.reportTime = report.time
              this.report = report.report
              this.reportReady = true
            })
            .catch(error => {
              this.report = []
              this.errorMessage = error
              this.reportError = true
            })
            .finally(()=>{
              this.loadingReport = false;
            })
        },        
    },
    async mounted() {
      await this.generate_report()
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .report-container {
        height: calc(100vh - 96px);
    }
</style>
