<template>
    <div class="container-fluid">
        <div class="d-flex-row justify-content-between">
            <template v-if="compactDisplay">
                <div class="justify-content-around d-flex flex-column">
                    <h5 class="text-info m-0">{{title}}</h5>
                    <h5 class="text-primary m-0">{{time}}</h5>
                </div>
            </template>
            <template v-else>
                <div class="justify-content-between align-content-between d-flex flex-row flex-wrap">
                    <div class="p-0 d-flex justify-content-start">
                        <h3 class="text-info">{{title}}</h3>
                    </div>
                    <div class="p-0 d-flex justify-content-end">
                        <h3 class="text-primary">{{time}}</h3>
                    </div>
                </div>
            </template>
            <div class="d-flex-row justify-content-between">
                <div class="p-1 d-inline-block col-6">
                    <div class="stacked-bar text-center">
                        <h6 class="text-success m-0">ONLINE</h6>
                        <h3 class="text-success m-0">{{get_online_count(devices)}}</h3>
                    </div>
                </div>
                <div class="p-1 d-inline-block col-6">
                    <div class="stacked-bar text-center">
                        <h6 class="text-danger m-0">OFFLINE</h6>
                        <h3 class="text-danger m-0">{{get_offline_count(devices)}}</h3>
                    </div>
                </div>
            </div>
            <div class="d-flex-row justify-content-between">
                <button v-on:click="csv_devices" class="d-inline-block col-1 btn btn-outline-primary btn-rounded download"><i class="mdi mdi-download"></i></button>
                <div class="d-inline-block col-11">
                    <div class="app-search">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search..." v-model="filter">
                            <span class="mdi mdi-magnify search-icon"></span>
                        </div>
                    </div>
                </div>
            </div>

            <template v-if="canProvision">
                <div class="d-flex-row justify-content-between">
                    <div class="p-1 my-2">
                        <DeviceProvisionForm :selected-devices="selectedDevices.map(device => { return device.uuid })" />
                    </div>
                </div>
            </template>
        </div>
        <div class="d-flex flex-row" v-if="!compactDisplay">
            <div class="device-selection ml-2 p-2 text-center align-self-center col-auto" v-if="canProvision">
            <div class="form-check">
                <input
                    class="form-check-input position-static"
                    type="checkbox"
                    id="device-select-all-none"
                    :checked="filteredOnlineDevices.length > 0 && selectedDevices.length === filteredOnlineDevices.length"
                    @click="() => {
                    if (selectedDevices.length < filteredOnlineDevices.length) {
                        onDeviceSelectAll();
                    } else {
                        onDeviceDeselectAll();
                    }
                    }"
                >
            </div>
            </div>
            <div class="device-status m-1 col-auto">STATUS</div>
            <div class="device-age m-1 col-1">LAST ONLINE</div>
            <div class="device-name m-2 col-2">NAME</div>
            <div class="device-uuid m-1 col-1">UUID</div>
            <div class="device-ips m-2 col-1">IPs</div>
            <div class="device-macs m-2 col-1">MACs</div>
            <div class="device-links m-1 col-auto">LINKS</div>
        </div>
        <scroll-area :striped="true" >
            <div v-for="device in filtered_devices" :key="device.name"
                :class="{   'device-table' : true,
                            'w-100' : true,
                            'd-inline-flex' : true,
                            'flex-wrap' : true,
                            'flex-row' : !compactDisplay,
                            'flex-column' : compactDisplay
                        }">
                <template v-if="compactDisplay">
                    <div class="d-flex flex-row justify-content-between">
                        <div class="device-selection p-2 align-self-center text-center" v-if="canProvision">
                        <div class="form-check">
                            <input
                                :disabled="device.status === 'Offline'"
                                :checked="selectedDevices.includes(device)"
                                class="form-check-input position-static"
                                type="checkbox"
                                @click="() => { onDeviceToggle(device); }"
                            >
                        </div>
                        </div>
                        <div class="device-status m-1">
                            <DeviceBadge :good="device.status==='Online'" :fail="device.status==='Offline'" :state="device.status" />
                        </div>
                        <div class="m-1 p-1">
                            {{device.age.substring(0,device.age.lastIndexOf('.'))}}
                        </div>
                    </div>
                    <div class="m-2 align-self-center">
                        <h4>{{device.name}}</h4>
                    </div>
                    <div class="d-flex flex-row justify-content-between">
                        <div class="m-1">
                            <h6>UUID</h6>
                            {{device.uuid.substring(0,7)}}
                        </div>
                        <div class="m-2 text-truncate">
                            <h6>IPs</h6>
                            <ul class="m-1 list-unstyled">
                                <li v-for="ip in device.ips" :key="ip">
                                {{ip}}
                                </li>
                            </ul>
                        </div>
                        <div class="m-2 text-truncate">
                            <h6>MACs</h6>
                            <ul class="m-1 list-unstyled">
                                <li v-for="mac in device.macs" :key="mac">
                                {{mac}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="device-links m-1 w-100">
                        <div class="links p-1 d-flex justify-content-between align-items-center">
                            <template v-if="check_services('portal-admin')">
                                <a class="btn btn-info" target="_blank" :href="get_portal_url(fleet, device.uuid)">
                                    <div class="box">
                                        <img :src="get_image_url('/portal-196.png')" alt="Virgo Admin Portal" />
                                    </div>
                                </a>
                            </template>
                            <template v-if="check_services('balena')">
                                <a class="btn btn-info" target="_blank" :href="device.balena_url">
                                    <div class="box">
                                        <img :src="get_image_url('/balena_logo.png')" alt="Balena.io" />
                                    </div>
                                </a>
                            </template>
                            <template v-if="check_services('datadog')">
                                <a class="btn btn-info" target="_blank" :href="device.datadog_url">
                                    <div class="box">
                                        <img :src="get_image_url('/data_dog.jpeg')" alt="DataDog.com" />
                                    </div>
                                </a>
                            </template>
                            <a class="btn btn-info" target="_blank" :href="get_device_url(fleet,device.uuid)">
                                <div class="box">
                                    <img :src="get_image_url('/virgo-dot.svg')" alt="Virgo" />
                                </div>
                            </a>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="device-selection p-2 align-self-center text-center" v-if="canProvision">
                    <div class="form-check">
                        <input
                            :disabled="device.status === 'Offline'"
                            :checked="selectedDevices.includes(device)"
                            class="form-check-input position-static"
                            type="checkbox"
                            @click="() => { onDeviceToggle(device); }"
                        >
                    </div>
                    </div>
                    <div class="device-status m-1 col-auto">
                        <DeviceBadge :good="device.status==='Online'" :fail="device.status==='Offline'" :state="device.status" />
                    </div>
                    <div class="device-age m-1 align-self-center col-1">
                        {{device.age.substring(0,device.age.lastIndexOf('.'))}}
                    </div>
                    <div class="device-name m-2 align-self-center col-2 text-truncate">
                        {{device.name}}
                    </div>
                    <div class="device-uuid m-1 align-self-center col-1">
                        {{device.uuid.substring(0,7)}}
                    </div>
                    <div class="device-ips m-2 align-self-center col-1 text-truncate">
                        <ul class="m-1 list-unstyled">
                            <li v-for="ip in device.ips" :key="ip">
                            {{ip}}
                            </li>
                        </ul>
                    </div>
                    <div class="device-ips m-2 align-self-center col-1 text-truncate">
                        <ul class="m-1 list-unstyled">
                            <li v-for="mac in device.macs" :key="mac">
                            {{mac}}
                            </li>
                        </ul>
                    </div>
                    <div class="device-links m-1 align-self-center col-auto">
                        <div class="links p-1 d-flex justify-content-start align-items-center">
                            <template v-if="check_services('portal-admin')">
                                <a class="btn btn-info" target="_blank" :href="get_portal_url(fleet, device.uuid)">
                                    <div class="box">
                                        <img :src="get_image_url('/portal-196.png')" alt="Virgo Admin Portal" />
                                    </div>
                                </a>
                            </template>
                            <template v-if="check_services('balena')">
                                <a class="btn btn-info" target="_blank" :href="device.balena_url">
                                    <div class="box">
                                        <img :src="get_image_url('/balena_logo.png')" alt="Balena.io" />
                                    </div>
                                </a>
                            </template>
                            <template v-if="check_services('datadog')">
                                <a class="btn btn-info" target="_blank" :href="device.datadog_url">
                                    <div class="box">
                                        <img :src="get_image_url('/data_dog.jpeg')" alt="DataDog.com" />
                                    </div>
                                </a>
                            </template>
                            <a class="btn btn-info" target="_blank" :href="get_device_url(fleet, device.uuid)">
                                <div class="box">
                                    <img :src="get_image_url('/virgo-dot.svg')" alt="Virgo" />
                                </div>
                            </a>
                        </div>
                    </div>
                </template>
            </div>
        </scroll-area>
    </div>
</template>

<script lang="ts">
import DeviceBadge from '@/components/DeviceBadge.vue'
import ScrollArea from '@/components/ScrollArea.vue'
import DeviceProvisionForm from '@/components/DeviceProvisionForm.vue'
import { access_methods, url_methods } from '@/models/hub'
import { Device } from '@/models/Devices'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
    name: 'DeviceTable',
    data() {
        return {
            filter: "",
            selectedDevices: Array<Device>(),
            device_table_left: 0,
            device_table_top: 0,
            device_table_width: 0,
            device_table_height: 0,
        }
    },
    props: {
        devices: Array as PropType<Array<Device>>,
        title: String,
        time: String,
        canProvision: Boolean,
        fleet: {
            type: String,
            require: true,
            default: ""
        }
    },
    computed: {
        compactDisplay() : boolean {
            return (window.innerWidth < 1200)
        },
        filtered_devices() : Device[] {
            return this.devices?.filter(device=>{
                if (this.filter === "") return true;

                const lower = this.filter.toLowerCase()
                const ip = device.ips.join(" ")
                const macs = device.macs.join(" ")

                return (device.name.toLowerCase().indexOf(lower) > -1 ||
                        device.uuid.toLowerCase().indexOf(lower) > -1 ||
                        ip.toLowerCase().indexOf(lower) > -1 ||
                        macs.toLowerCase().indexOf(lower) > -1 || 
                        device.status.toLowerCase().indexOf(lower) > -1);
            })??[]
        },
        filteredOnlineDevices() : Device[] {
            return this.filtered_devices.filter(device => device.status === 'Online');
        }
    },
    emits: [
    ],
    components: {
        DeviceProvisionForm,
        DeviceBadge,
        ScrollArea
    },
    methods: {
        ...url_methods,
        ...access_methods,
        csv_devices() {
                if (this.filtered_devices === undefined || 
                    this.filtered_devices.length === 0) {
                    return
                }

                let csv = "data:text/csv;charset=utf-8,"
                csv += [
                    [...Object.keys(this.filtered_devices[0])].join(","),
                    ...this.filtered_devices.map(device => Object.values(device).map(val=>`"${val.toString()}"`).join(","))
                ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");
                const encoded_csv =  encodeURI(csv)

                const link = document.createElement("a");

                link.setAttribute("href", encoded_csv);
                if (this.filter.length > 0)
                {
                    link.setAttribute("download", `devices_${this.filter}.csv`);
                } else {
                    link.setAttribute("download", "devices.csv");
                }
                link.click();
        },
        deviceTableDimension(
            left : number, 
            top : number, 
            width : number, 
            height : number ) {
            this.device_table_left = left
            this.device_table_top = top
            this.device_table_width =  width
            this.device_table_height = height
        },
        get_online_count(devices : Array<Device> | undefined) {
            let count = 0;
            devices?.forEach(device => {
                if (device.status === "Online")
                {
                    count += 1;
                }
            });

            return count;
        },
        get_offline_count(devices : Array<Device> | undefined) {
            let count = 0;
            devices?.forEach(device => {
                if (device.status === "Offline")
                {
                    count += 1;
                }
            });

            return count;
        },
        onDeviceSelect(device : Device) {
            this.selectedDevices.push(device);
        },
        onDeviceDeselect(device : Device) {
            this.selectedDevices.filter(selected => selected.uuid === device.uuid);
        },
        onDeviceToggle(device : Device) {
            const isSelected = this.selectedDevices.includes(device);
            if (isSelected) {
                this.onDeviceDeselect(device);
            } else {
                this.onDeviceSelect(device);
            }
        },
        onDeviceSelectAll() {
            this.selectedDevices = this.filtered_devices?.filter(device => device.status === 'Online') ?? []
        },
        onDeviceDeselectAll() {
            this.selectedDevices = [];
        },
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
    width: 100%;
    height: 100%;
}

.box {
    width: 24px;
    height: 24px;
}

.device-table-striped > div:nth-of-type(odd) {
    background: #464f5b;
}

.device-table-body {
    overflow: auto;
}

.device-status {
    width: 100px;
}

.links {
    padding: 8px;
    display: flex;
    align-content: space-between;
    gap: 16px;
}

.device-selection [type="checkbox"] {
  transform: scale(1.5);
  cursor: pointer;
  accent-color: #707aef;
}
</style>
