<template>
    <div class="w-100">
        <div class="row">
            <p :class="`${column.class} text-dark`" v-for="(column, index) in columns" :key="index">{{column.name}}</p>
        </div>
        <div class="table-row" :style="table_styles">
            <div v-for="(row, index) in row_columns" :key="index" class="row">
                <p v-for="(cell, cindex) in row.cells" :key="cindex" :class="cell.class">{{cell.value}}</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import GenericColumn from '@/models/GenericColumn'
import { defineComponent } from 'vue'

interface TableRow {
    cells : {
        value: string;
        class: string;
    } [];
}

export default defineComponent({
    name: 'GenericTable',
    props: {
        columns: {
            type: Array as () => Array<GenericColumn>,
            default: () => []
        },       
        items: {
            type: Array as () => Array<object>,
            default: () => []
        },
        maxHeight: {
            type: String,
            default: () => "300px"
        }
    },
    data() { 
        return{
        filter: "",
    }},
    emits: [
    ],
    components: {
    },
    methods:
    {
    },
    watch: {
    },
    computed: {
        table_styles() : string {
            return `max-height: ${this.maxHeight}; min-height: 180px`
        },
        row_columns() : TableRow[]  {
            return this.items?.map((value: object) : TableRow =>{
                const row : TableRow = { cells: []};

                if (value.constructor.name == 'Array') {
                    const array = value as Array<string | number | object>

                    this.columns?.forEach((genericColumn): void => {
                        if (genericColumn.index)
                        {
                            row.cells.push({ 
                                value: genericColumn.format?genericColumn.format(array[genericColumn.index]):array[genericColumn.index].toString(), 
                                class: genericColumn.class 
                            })
                        }
                    });
                } else {
                    const entries = Object.entries(value);

                    this.columns?.forEach(genericColumn => {
                        entries.forEach(entry=>{
                            if (entry[0] === genericColumn.member) {
                                row.cells.push({ 
                                    value: genericColumn.format?genericColumn.format(entry[1]):entry[1], 
                                    class: genericColumn.class 
                                })
                            }
                        })
                    });
                }

                return row;
            }, this)
        },
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .table-row {
        overflow-x: hidden;
        overflow-y: auto;
    }

    .table-row div:nth-of-type(odd) {
        background: #464f5b;
    }
</style>
