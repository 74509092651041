<template>
    <div>
        <template v-if="!ready">
            <div class="row">
                <div class="spinner-grow avatar-lg text-info col-4 loading-spinner" role="status">
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="error" class="text-warning">{{error}}</div>
            <template v-else>
                <apexcharts :class="get_classes" 
                            :width="width" 
                            :height="height" 
                            type="line" 
                            :series="series"
                            :options="options">
                </apexcharts>
            </template>
        </template>
    </div>
</template>

<script lang="ts">
import { hosturl } from "@/models/hub"
import { defineComponent } from "vue"
import VueApexCharts from "vue3-apexcharts"
import { GraphingColors, NotificationColors } from "@/models/UI"
import { DataDogLogSeries } from "@/models/Logs"

interface LogSet {
    [key: number] : { [key: string] : number }
}

export default defineComponent({
    name: 'LogGraph',
    props: {
        classes: String,
        width: String,
        height: String,
        host: String,
        id: String as () => "device-log-graph" | string,
    },
    data() {
        return {
            log_series: {} as DataDogLogSeries,
            ready: false,
            error: '',
        }
    },
    components: {
        apexcharts: VueApexCharts
    },
    methods: {
        async get_log_series() {
            this.ready = false
            const url = new URL(`${hosturl}/api/datadog/aggregate`)

            url.searchParams.append("query",`host:*${this.host}*`)
            url.searchParams.append("facet","status")
            url.searchParams.append("start","now-30d")
            url.searchParams.append("interval","1d")
            url.searchParams.append("type","timeseries")

            await fetch(url.toString())
                .then(response => {
                    if (response.status != 200)
                    {
                        throw `STATUS: ${response.status} - Failed!`
                    }
                    return response.json()
                })
                .then(logs => {
                    this.log_series = logs
                    this.build_log_sets(logs)
                    this.ready = true
                })
                .catch(error => {
                    this.error = `${url.toString()} ${error}`
                    this.log_series = {} as DataDogLogSeries
                })
                .finally(()=>{
                    this.ready = true
                })
        },
        get_set_series(set : LogSet) {
            const series_length = Object.keys(set).length

            const series = {
                'info' : new Array(series_length).fill(0),
                'warn':  new Array(series_length).fill(0),
                'error': new Array(series_length).fill(0)
            } as {
                [key: string] : number[]
            }

            const times = Object.keys(set)

            times.forEach((time, index)=>{
                const statuses = Object.keys(set[+time])

                statuses.forEach(stat=>{
                    if (!(stat in series)) {
                        series[stat] = new Array(series_length).fill(0)
                    }

                    series[stat][index] = set[+time][stat]
                })
            })

            return {
                sets: series,
                stamps: times.sort().map(time=>+time)
            }
        },
        build_log_sets(logs : DataDogLogSeries | undefined) {
            const log_sets : LogSet = {}

            if (logs === undefined) {
                return this.get_set_series(log_sets)
            }

            logs.data.buckets.forEach(bucket=>{
                const label = bucket.by.status

                Object.keys(bucket.computes).forEach(key => {
                    bucket.computes[key].value.forEach(sample=>{
                        const ts = Date.parse(sample.time)
                        if (!(ts in log_sets)) {
                            log_sets[ts] = {}
                        }

                        log_sets[ts][label] = sample.value
                    })
                })
            })

            return this.get_set_series(log_sets)
        },
    },
    async mounted() {
        await this.get_log_series()
    },
    computed: {
        options() {
            return {
                chart: {
                    id: this.id,
                    background: '#37404a'
                },
                xaxis: {
                    type: 'datetime',
                    label: {
                        show: true,
                    }
                },
                yaxis: [
                    {
                        seriesName: 'error',
                        show: false
                    },
                    {
                        seriesName: 'warn',
                        show: false
                    },
                    {
                        seriesName: 'info',
                        show: true,
                    }
                ],                
                theme: {
                    mode: 'dark',
                }                
            };
        },
        series() {
            const temp_series = this.build_log_sets(this.log_series)
            const colors = GraphingColors
            const series = []
            let index = 0

            const lookups = {
                'info' : NotificationColors.PASS,
                'warn' : NotificationColors.WARN,
                'error' : NotificationColors.FAIL,
            } as {[key:string] : string}

            for (const key in temp_series.sets) {
                series.push({
                    name: key,
                    color: (key in lookups)?lookups[key]:colors[index % colors.length],
                    data: temp_series.sets[key].map((temp,i)=>{ return [temp_series.stamps[i], temp]})
                })
                index ++
            }

            return series
        },        
        get_classes() {
            if (this.classes === undefined) {
                return "w-100"
            }

            return this.classes
        }
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

/*
{
    'data': {
        'buckets': [
            { 
                'by': {'status': 'info'},
                'computes': {
                    'c0': {
                        'value': [
                            {'time': '2021-11-04T00:00:00.000Z', value': 58053.0},
                            {'time': '2021-11-05T00:00:00.000Z','value': 62761.0},
                            {'time': '2021-11-06T00:00:00.000Z','value': 62365.0},
                            ...
                            {'time': '2021-11-18T00:00:00.000Z','value': 4567.0}
                        ]
                    }
                }
            }
        ]
    }, 'meta': {'elapsed': 249,          'request_id': 'pddv1ChY5VURsUHdhdVIxS0g2NDFZSEtmaURnIi0KHRqoq-8OzbUR_ar4unUT2O9Ca3nD1I-eG_I77wojEgz9jCMWH9jzxe-6FiI',          'status': 'done'}}
*/
