export enum NotificationColors {
    DISABLED = "#464f5b",
    PASS = "#00DD00",
    WARN = "#DDDD00",
    FAIL = "#DD0000"
}

export const GraphingColors = [
        '#DD0000',
        '#00DD00',
        '#0000DD',
        '#DDDD00',
        '#00DDDD',
        '#DD00DD',
        '#DDDD33',
        '#33DDDD',
        '#DD33DD',
]