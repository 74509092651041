<template>
  <div class="content-page">
    <div v-if="!check_fleet(fleet)">
      <h3 class="text-warning">FLEET NOT AVAILABLE...</h3>
    </div>
    <template v-else>
      <div v-if="loadingReport" class="text-center">
        <h3>{{reportMessage}}</h3>
        <div class="spinner-grow avatar-lg text-info" style="padding: 10%; margin: 10%;" role="status">
        </div> 
      </div>
      <div v-if="reportError">
        <h2 class="text-warning">ERROR: {{errorMessage}}</h2>
      </div> 
      <div v-if="reportReady" class="report-container">
        <h2 class="text-info">FAILURE REPORT FOR {{ fleet }} [{{reportTime}}]</h2>
        <FailureTable :report="report" :sling="false" />
      </div> 
    </template>
  </div>
</template>

<script>
  import { hosturl, access_methods } from '@/models/hub.ts'
  import { useRoute } from 'vue-router'
  import FailureTable from '@/components/FailureTable.vue'

  export default {
    name: 'CometBalenaReport',
    props: {
    },
    components: {
      FailureTable,
    },
    data: ()=>{
      const route = useRoute();

      return {
        reportTime: '',
        loadingReport: false,
        reportReady: false,
        reportError: false,
        errorMessage: 'NA',
        reportMessage: 'Standby...',
        report: [],
        fleet: route.params.fleet,
      }
    },
    methods: {
        ...access_methods,
        async refresh_report() {
          await this.generate_report()
        },
        async generate_report() {
          this.reportReady = false
          this.loadingReport = true
          this.reportMessage = "Checking Balena..."

          let status = 202

          while (status == 202) {
            await fetch(`${hosturl}/api/comet/failures/${this.fleet}`)
            .then(response => {
              status = response.status

              if (response.status > 202)
              {
                throw `STATUS: ${response.status} - Failed!`
              }
              return response.json()
            })
            .then(report => {
              if (status == 202) {
                this.reportMessage = report.status
              } 
              this.report = report.report
              this.reportTime = report.time
            })
            .catch(error => {
              this.report = []
              this.errorMessage = error
              this.reportError = true
              this.loadingReport = false
            })

            await new Promise(f => setTimeout(f, 1000));
          }

          if (status == 200)
          {
            status = 202
            this.reportMessage = "Checking logs..."
          }

          while (status == 202) {
            await fetch(`${hosturl}/api/comet/evaluations/${this.fleet}`)
              .then(response => {
                status = response.status
                if (response.status > 202)
                {
                  throw `STATUS: ${response.status} ${response.statusText} - Failed!`
                }
                return response.json()
              })
              .then(report => {
                if (status == 200) {
                  this.report = [...report.report, ...this.report]
                  this.reportReady = true
                } else {
                  this.reportMessage = report.status
                }
              })
              .catch(error => {
                this.report = []
                this.errorMessage = error
                this.reportError = true
                this.loadingReport = false
              })
              await new Promise(f => setTimeout(f, 1000));
            }

            this.loadingReport = false
        },        
    },
    async mounted() {
      await this.generate_report()
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .report-container {
        height: calc(100vh - 96px);
    }
</style>
