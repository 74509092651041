<template>
    <div id='map_box' :style="cssStyle" class="w-100">
    </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

export default {
    props: [ 'width', 'height', 'zoom', 'locations'],
    watch: {
        locations: function (val) {
            this.addLocations(val)
        }
    },
    components: {
    },
    data() {
        return { 
            loadingLocations: false,
            locationsReady: false,
            deviceError: false,
            errorMessage: 'NA',
            map: null,
        }
    },
    mounted() {
        this.createMap(this.zoom)
    },
    computed: {
        cssStyle() {
            return {
                width: this.width,
                height: this.height
            }
        }
    },
    methods: {
        async createMap(zoom) {
            mapboxgl.accessToken = 'pk.eyJ1IjoiY2xhd2NvY2siLCJhIjoiY2t1aWgzZGlnMm54cTJubzg5Mm52ejNuMSJ9.DtouZ4lyR6-lVees90TGtg'
            this.map = new mapboxgl.Map({
                container: 'map_box', // container element id
                style: 'mapbox://styles/clawcock/ckuigscu31xmb18pebg36aje7',
                zoom: zoom,
                center: [-96.236,38.848]
            })   
            await this.get_locations()
        },
        async get_locations() {
            this.locationsReady = false
            this.loadingLocations = false
        },
        addLocations(locations) {
            locations.forEach(location => {
                new mapboxgl.Marker()
                    .setLngLat([location.lon, location.lat])
                    .addTo(this.map);            
            });
        }
    }
};
</script>