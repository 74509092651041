<template>
    <div v-if="ready">
        <div class="d-flex flex-column justify-content-around">
            <div class="justify-content-between d-flex flex-row flex-wrap">
                <div class="p-0 d-flex justify-content-start">
                    <h4 class="text-info align-items-center">{{device.info.device_name}}</h4>
                </div>
                <div class="p-0 d-flex justify-content-end">
                    <h4 class="text-primary">{{device.time}}</h4>
                </div>
            </div>
            <div class="col-12 links align-items-center d-flex flex-row flex-wrap justify-content-center">
                <template v-if="fleet && check_services('portal-admin')">
                    <a class="btn btn-success" target="_blank" :href="get_portal_url(fleet, device_uuid)">
                        <div class="box">
                            <img :src="get_image_url('/portal-196.png')" alt="Virgo Admin Portal" />
                        </div>
                    </a>
                </template>
                <template v-if="check_services('balena')">
                    <a class="btn btn-success" target="_blank" :href="device.balena_url">
                        <div class="box"> 
                            <img :src="get_image_url('/balena_logo.png')" alt="Balena.io" />
                        </div>
                    </a>
                </template>
                <template v-if="check_services('datadog')">
                    <a class="btn btn-success" target="_blank" :href="device.datadog_url">
                        <div class="box"> 
                            <img :src="get_image_url('/data_dog.jpeg')" alt="DataDog.com" />
                        </div>
                    </a>
                </template>
                <a :class="{btn: true, 'btn-warning' : !rebooting_error, 'btn-danger' : rebooting_error}" @click="reboot_device()">
                    <div class="box"> 
                        <img :src="get_image_url('/reboot.svg')" alt="Reboot Device" :class="{'spinner-grow': rebooting}"/>
                    </div>
                </a>
                <a :class="{btn: true, 'btn-warning' : !restarting_error, 'btn-danger' : restarting_error}" @click="restart_services()">
                    <div class="box"> 
                        <img :src="get_image_url('/restart.svg')" alt="Restart Services" :class="{'spinner-grow': restarting}"/>
                    </div>
                </a>
                <a :class="{btn: true, 'btn-info' : !show_config, 'btn-light' : show_config, 'h-100' : true }" @click="show_config=false">
                    <span class="font-weight-bold"> 
                        DASHBOARD
                    </span>
                </a>
                <a :class="{btn: true, 'btn-info' : show_config, 'btn-light' : !show_config, 'h-100' : true }" @click="show_config=true">
                    <div class="font-weight-bold"> 
                        CONFIGURATION
                    </div>
                </a>
            </div>
        </div>
        <ScrollArea>
            <div class="d-flex flex-row flex-wrap justify-content-center">
                <template v-if="device.info.is_online">
                    <h4 class="text-dark">{{device_boot}}</h4>
                </template>
                <template v-else>
                    <h4 class="text-warning">Last contact @{{device.last_seen.substring(0,device.last_seen.lastIndexOf('.'))}}</h4>
                </template>
            </div>
            <div class="col-12 align-self-center d-flex flex-row justify-content-center flex-wrap bg-light-lighten">
                <device-badge :good="device.tags.includes('PERMANENT-POWER') || device.tags.includes('ACTIVE')">
                    <h4 class="m-0">FIXED POWER</h4>
                </device-badge>
                <device-badge :good="device.info.is_online">
                    <h4 class="m-0">ONLINE</h4>
                </device-badge>
                <device-badge :good="device.info.is_connected_to_vpn">
                    <h4 class="m-0">VPN</h4>
                </device-badge>
                <template v-if="device.device_status !== undefined">
                    <device-badge :good="device.device_status.streaming===true" class="device-badge device-badge-invert">
                        <img src='images/endo.svg'/>
                    </device-badge>
                    <device-badge v-if="is_disabled(device.device_status.hdmi0)" :good="device.device_status.hdmi0==='online'" class="device-badge">
                        <img src='images/hdmi.svg' />
                    </device-badge>
                    <device-badge v-if="is_disabled(device.device_status.hdmi1)" :good="device.device_status.hdmi1==='online'" class="device-badge">
                        <img src='images/hdmi.svg' />
                    </device-badge>
                    <device-badge v-if="is_disabled(device.device_status.eth0)" :good="device.device_status.eth0==='online'" class="device-badge">
                        <img src='images/eth.svg' />
                    </device-badge>
                    <device-badge v-if="is_disabled(device.device_status.eth1)" :good="device.device_status.eth1==='online'" class="device-badge">
                        <img src='images/eth.svg' />
                    </device-badge>
                    <device-badge v-if="is_disabled(device.device_status.wifi)" :good="device.device_status.wifi==='online'" class="device-badge">
                        <img src='images/wifi.svg' />
                    </device-badge>
                    <device-badge :good="device.device_status.ariane!==false" class="device-badge">
                        <img src='images/ariane.svg' />
                    </device-badge>
                    <device-badge   :good="device.device_status.vpn==='online' && device.device_status.proxy!=='online'" 
                                    :warn="device.device_status.vpn==='online' && device.device_status.proxy==='online'" 
                                    class="device-badge device-badge-invert">
                        <div>
                            <img src='images/vpn.svg' />
                            <p v-if="device.device_status.proxy==='online'">
                                P
                            </p>
                        </div>
                    </device-badge>
                </template>
            </div>
            <DeviceConfiguration v-if="show_config" />
            <template v-else>
                <div class="card border">
                    <div class="card-header">
                        <h4 class="text-dark text-center">METRICS</h4>
                    </div>
                    <div class="card-body w-100 card-deck">        
                        <device-bandwidth-gauge v-if="device.device_status !== undefined" :transport="device.transport" />
                        <div class="card border border-secondary card-margin">
                            <div class="card-header">
                                <h4 class="text-dark text-center">TEMPERATURE</h4>
                            </div>
                            <div class="row align-self-center">
                                <gauge :label="temperature_string" :color="temperature_color" :reading="temperature_percent" type="radialBar" />
                            </div>
                            <div class="card-footer">
                                <h5 class="text-dark text-center">{{temperature_source}}</h5>
                            </div>
                        </div>
                        <div class="card border border-secondary card-margin">
                            <div class="card-header">
                                <h4 class="text-dark text-center">CPU</h4>
                            </div>
                            <gauge :label="cpu_count" :color="cpu_color" :reading="cpu_percentage" type="radialBar" class="row justify-content-center" />
                        </div>
                        <div class="card border border-secondary card-margin">
                            <div class="card-header">
                                <h4 class="text-dark text-center">MEMORY</h4>
                            </div>
                            <gauge :label="memory_size" :color="memory_color" :reading="memory_percentage" type="radialBar" class="row justify-content-center" />
                        </div>
                        <div class="card border border-secondary card-margin">
                            <div class="card-header">
                                <h4 class="text-dark text-center">STORAGE</h4>
                            </div>
                            <gauge :label="storage_size" :color="storage_color" :reading="storage_percentage" type="radialBar" class="row justify-content-center" />
                        </div>
                    </div>
                </div>
                <div class="card border">
                    <div class="card-header">
                        <h4 class="text-dark text-center">NETWORKING</h4>
                    </div>
                    <div class="card-body card-deck">
                        <template v-if="device.network">
                            <device-networking :network="device.network" />
                        </template>
                        <device-cert-info :cert_info="device.cert_info" />
                    </div>
                </div>            
                <template v-if="device.device_status !== undefined">
                    <div class="card border border-secondary">
                        <div class="card-header">
                            <h4 class="text-dark text-center">INFERENCES</h4>
                        </div>
                        <device-inference-graph height="200px" :logs="device.inference" />
                    </div>                    
                    <div class="card border border-secondary">
                        <div class="card-header">
                            <h4 class="text-dark text-center">LOGS</h4>
                        </div>
                        <log-graph height="200px" :logs="device.device_statuses" id="log-graphs" :host="device.info.device_name" />
                    </div>                    
                    <div class="card border border-secondary">
                        <div class="card-header">
                            <h4 class="text-dark text-center">TEMPERATURES</h4>
                        </div>
                        <device-temp-graph height="200px" :logs="device.device_statuses" id="temp-graphs" />
                    </div>
                    <div class="card border border-secondary">
                        <div class="card-header">
                            <h4 class="text-dark text-center">NETWORK</h4>
                        </div>
                        <device-pending-graph height="200px" :logs="device.transport" />
                    </div>
                    <div class="card border border-secondary">
                        <div class="card-header">
                            <h4 class="text-dark text-center">MEMORY</h4>
                        </div>
                        <device-memory-graph height="200px" :logs="device.device_statuses" />
                    </div>
                    <div class="card border border-secondary">
                        <div class="card-header">
                            <h4 class="text-dark text-center">CPU</h4>
                        </div>
                        <device-cpu-graph height="200px" :logs="device.device_statuses" />
                    </div>
                </template>
                <div class="card border border-secondary" v-if="!compactDisplay">
                    <div class="card-header">
                        <h3 class="text-dark">BALENA</h3>
                    </div>
                    <div class="card-body">
                        <div class="d-flex flex-row flex-wrap">
                            <h3 class="text-dark w-15">UUID</h3>
                            <h3 class="text-success">{{device.info.uuid}}</h3>
                        </div>
                        <div class="d-flex flex-row flex-wrap">
                            <h4 class="text-dark w-15">DEVICE TYPE</h4>
                            <h4 class="text-success">{{device.info.device_type}}</h4>
                        </div>
                        <div class="d-flex flex-row flex-wrap">
                            <h4 class="text-dark w-15">OS</h4>
                            <h4 class="text-success">{{device.info.os_version}}</h4>
                        </div>
                        <div class="d-flex flex-row flex-wrap">
                            <h4 class="text-dark w-15">SUPERVISOR</h4>
                            <h4 class="text-success">{{device.info.supervisor_version}}</h4>
                        </div>
                        <div class="d-flex flex-row flex-wrap">
                            <h4 class="text-dark w-15">LOCATION</h4>
                            <h4 class="text-success">{{device.info.location}}</h4>
                        </div>
                        <div class="d-flex flex-row flex-wrap">
                            <h4 class="text-dark w-15">IPs</h4>
                            <h4 class="text-success">{{device.info.ip_address}}</h4>
                        </div>
                        <div class="d-flex flex-row flex-wrap">
                            <h4 class="text-dark w-15">MAC IDs</h4>
                            <h4 class="text-success">{{device.info.mac_address}}</h4>
                        </div>
                        <div class="d-flex flex-row flex-wrap">
                            <h4 class="text-dark w-15">NOTES</h4>
                            <h4 class="text-success">{{device.info.note}}</h4>
                        </div>
                        <div class="d-flex flex-row flex-wrap">
                            <h4 class="text-dark w-15">TAGS</h4>
                            <div class="col-8 d-flex flex-row flex-wrap">
                                <device-badge v-for="name in device.tags" good :key="name" class="device-badge">
                                    {{name}}
                                </device-badge>
                            </div>
                        </div>
                        <div class="d-flex flex-row flex-wrap">
                            <h4 class="text-dark w-15">METRICS</h4>
                            <div class="w-100">
                                <div v-for="(value,key) in device.metrics" :key="key" class="row">
                                    <h6 class="col-3 text-dark text-uppercase col-2">{{key}}</h6>
                                    <h6 class="col-9 text-success col-auto">{{value}}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row flex-wrap">
                            <h4 class="text-dark col-2">LOGS</h4>
                            <div class="card border border-secondary card-margin col-12">
                                <div class="card-body">
                                    <template v-if="device.logsDatadog == true">
                                        <h3 class="text-warning">NO LOGS</h3>
                                    </template>
                                    <template v-else>
                                        <generic-table
                                            maxHeight="300px"

                                            :columns="[
                                                {
                                                    name:'TIME',
                                                    class:'col-3 text-nowrap',
                                                    member: 'timestamp',
                                                    format: (value) => { if (typeof value === 'object') return 'NA'; return new Date(value).toISOString(); }
                                                },
                                                {
                                                    name:'MESSAGE',
                                                    class:'col-9',
                                                    member: 'message'
                                                }]" 
                                            :items="device.logs" 
                                        />
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="device.logsDatadog">
                        <div class="row">
                            <div class="card border border-secondary card-margin w-100">
                                <div class="card-header">
                                    <h3 class="text-dark">DATADOG</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <h4 class="text-dark col-2">LOGS</h4>
                                        <div class="card border border-secondary card-margin col-12">
                                            <div class="card-body">
                                                <generic-table
                                                    maxHeight="300px"

                                                    :columns="[
                                                        {
                                                            name:'TIME',
                                                            class:'col-3 text-nowrap',
                                                            member: 'timestamp',
                                                            format: (value) => { if (typeof value === 'object') return 'NA'; return new Date(value).toISOString(); }
                                                        },
                                                        {
                                                            name:'MESSAGE',
                                                            class:'col-9',
                                                            member: 'message'
                                                        }]" 
                                                    :items="device.logs" 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </ScrollArea>
    </div>
    <div v-if="loading" class="text-center">
        <h3>{{message}}</h3>
        <div class="spinner-grow avatar-lg text-info" style="padding: 10%; margin: 10%;" role="status"></div> 
    </div>
    <div v-if="error">
        <h2>{{errorMessage}}!</h2>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { hosturl, access_methods, url_methods } from '@/models/hub'
import { NotificationColors } from '@/models/UI'
import { useRoute } from 'vue-router'
import Gauge from '@/components/Gauge.vue'
import DeviceBadge from '@/components/DeviceBadge.vue'
import GenericTable from '@/components/GenericTable.vue'
import DeviceTempGraph from '@/components/DeviceTempGraph.vue'
import DeviceMemoryGraph from '@/components/DeviceMemoryGraph.vue'
import DeviceCpuGraph from '@/components/DeviceCpuGraph.vue'
import LogType, { Log, DeviceStatusInfo, TransportInfo, ClassifierInfo, NetworkInfo, StorageInfo, TemperatureInfo, CPUSummary, ProcessorTools, LogInfo, InferenceInfo } from '@/models/Logs'
import DeviceNetworking from '@/components/DeviceNetworking.vue'
import DeviceBandwidthGauge from '@/components/DeviceBandwidthGauge.vue'
import DevicePendingGraph from '@/components/DevicePendingGraph.vue'
import DeviceInferenceGraph from '@/components/DeviceInferenceGraph.vue'
import DeviceCertInfo from '@/components/DeviceCertInfo.vue'
import DeviceConfiguration from '@/components/DeviceConfiguration.vue'
import LogGraph from '@/components/LogGraph.vue'
import ScrollArea from '@/components/ScrollArea.vue'
import { CertInfo } from '@/models/Devices'

interface DeviceTags {
    [key: string] : {
        id: string
        value: string
    }
}

interface DeviceSummary {
    time: string
    info: {[key: string] : string}
    age: string
    last_seen: string
    cpu?: CPUSummary
    drive?: StorageInfo
    highTemp?: {
        source: string
        input: number
        max: number
        critical: number
    }
    device_status?: DeviceStatusInfo
    device_statuses?: DeviceStatusInfo[]
    transport?: TransportInfo[]
    inference?: InferenceInfo[]
    logs: Log[]
    network: NetworkInfo
    metrics: {[key: string] : string | number}
    logsDatadog: boolean
    cert_info: CertInfo
    tags: string[]
    balena_url: string
    datadog_url: string
}

export default defineComponent({
    name: 'DeviceDashboard',
    props: {
    },
    data(){
        const route = useRoute();

        return {
            device: {} as DeviceSummary,
            device_uuid: route.params.uuid as string,
            fleet: route.params.fleet as string,
            ready: false,
            loading: false,
            rebooting: false,
            rebooting_error: false,
            restarting: false,
            restarting_error: false,
            show_config: false,
            errorMessage: '',
            error: false,
            message: 'Loading...'       
        }
    },
    mounted() {
        this.get_device(this.device_uuid)
    },  
    emits: [
    ],
    components: {
        Gauge,
        DeviceBadge,
        GenericTable,
        DeviceTempGraph,
        DeviceCpuGraph,
        DeviceMemoryGraph,
        DeviceNetworking,
        DeviceBandwidthGauge,
        DevicePendingGraph,
        DeviceInferenceGraph,
        LogGraph,
        DeviceCertInfo,
        DeviceConfiguration,
        ScrollArea
    },
    methods: {
        ...url_methods,
        ...access_methods,
        get_device(uuid : string) {
            this.ready = false
            this.loading = true

            fetch(`${hosturl}/api/device/${uuid}`)
            .then(response => {
                if (response.status != 200) {
                    throw `STATUS: ${response.status} - Failed!`
                }

                return response.json()
            })
            .then(device => {
                this.device = device

                this.device.logsDatadog = false

                if (device.tags) {
                    const tags = device.tags as DeviceTags
                    const string_tags = Object.keys(tags).map(key=>key.toUpperCase())

                    //Normalize tags because humans don't
                    this.device.tags = string_tags;
                }

                if (this.device.logs === undefined || this.device.logs.length === 0){
                    this.message = "No logs... Using DataDog..."
                    const url = new URL(`${hosturl}/api/datadog/logs`)

                    url.searchParams.append('host', this.device.info.device_name)
                    url.searchParams.append('start', 'now-30d')
                    url.searchParams.append('end', 'now')
                    url.searchParams.append('limit', '1000')
                    url.searchParams.append('sort', '-timestamp')

                    return fetch(url.toString())
                }
            })
            .then(response => {
                if (response === undefined) {
                    return
                }

                if (response.status != 200) {
                    throw `STATUS: ${response.status} - Failed!`
                }

                return response.json()
            })
            .then(datadog => {
                if (datadog !== undefined) {
                    this.device.logsDatadog = true

                    // eslint-disable-next-line
                    this.device.logs = datadog.map((log : any)=>{
                        const blog = { 
                            timestamp: Date.parse(log.attributes.timestamp),
                            message: ('attributes' in log.attributes)?JSON.stringify(log.attributes.attributes):log.attributes.message 
                        }

                        return blog
                    })
                }

                this.device.logs.sort((a : Log,b : Log)=> { return b.timestamp - a.timestamp})

                this.generate_sets()
                this.get_cpu()
                this.get_drive()
                this.get_temp()
                this.ready = true
            })
            .catch(error => {
                this.device = {} as DeviceSummary
                this.errorMessage = error
                this.error = true
            })
            .finally(()=>{
                this.loading = false;
            })
        },
        get_temp() {
            this.device.highTemp = undefined

            if (this.device.device_status === undefined ||
                this.device.device_status.temps === undefined)
            {
                if (this.device.metrics !== undefined)
                {
                    this.device.highTemp = {
                        input: +this.device.metrics["cpu_temp"],
                        max: 100,
                        critical: 100,
                        source: "cpu_temp"
                    }                
                }
                return
            }

            let highestTemp = 0
            let highInfo: TemperatureInfo = {}
            let source = "NA"

            this.device.device_statuses?.forEach(status=>{
                for (const key in status.temps) {
                    const tempInfo : TemperatureInfo = status.temps[key] as TemperatureInfo

                    if (tempInfo === undefined ||
                        !('Adapter' in tempInfo)) {
                        continue
                    }

                    for(const field in tempInfo)
                    {
                        if (field === "Adapter") {
                            continue
                        }

                        for(const readings in tempInfo[field]) {
                            if (!readings.endsWith("_input")) {
                                continue
                            }

                            const temp : number = (tempInfo[field][readings] as unknown) as number

                            if (highestTemp < temp)
                            {
                                source = field
                                highestTemp = temp
                                highInfo = tempInfo[field] as TemperatureInfo
                            }
                        }
                    }
                }
            })

            if (highInfo == null) {
                return
            }

            this.device.highTemp = {
                input: highestTemp,
                max: 100,
                critical: 100,
                source: source
            }

            for (const key in highInfo) {
                if (key.endsWith('_input')) {
                    this.device.highTemp.input = (highInfo[key] as unknown) as number
                }

                if (key.endsWith('_max')) {
                    this.device.highTemp.max = (highInfo[key] as unknown) as number
                }

                if (key.endsWith('_crit')) {
                    this.device.highTemp.critical = (highInfo[key] as unknown) as number
                }
            }

            if (this.device.highTemp.critical === 0 &&
                this.device.highTemp.max !== 0)
            {
                this.device.highTemp.critical = this.device.highTemp.max
            }

            if (this.device.highTemp.max === 0 && 
                this.device.highTemp.critical !== 0)
            {
                this.device.highTemp.max = this.device.highTemp.critical
            }
        },
        get_cpu() {
            this.device.cpu = undefined

            if (this.device.device_status === undefined ||
                this.device.device_status.processor === undefined ||
                this.device.device_status.processor.cpu === undefined)
            {
                if (this.device.metrics !== undefined)
                {
                    this.device.cpu = {
                        idle : 100 - (+this.device.metrics["cpu_usage"]),
                        usage: +this.device.metrics["cpu_usage"],
                        raw: {
                            idle : 100 - (+this.device.metrics["cpu_usage"]),
                            usage: +this.device.metrics["cpu_usage"],
                            total: 100
                        }
                    }
                }

                return
            }

            this.device.cpu = ProcessorTools.get_summary(this.device.device_status.processor.cpu)


            this.device.cpu.usage = Math.round(this.device.cpu.usage)
            this.device.cpu.idle = Math.round(this.device.cpu.idle)
        },
        get_drive() {
            this.device.drive = undefined

            if (this.device.device_status === undefined)
            {
                return
            }

            this.device.device_status.storage?.forEach(fileSystem=> {
                if (fileSystem.Filesystem?.startsWith("/dev")) {
                    this.device.drive = fileSystem
                }
            })
        },
        generate_sets() {
            this.device.device_status = undefined
            this.device.device_statuses = []
            this.device.transport = []
            this.device.inference = []

            this.device.logs.forEach((log : Log) => {
                const message : string = log.message.trim()
                if (message.startsWith("{") &&
                    message.endsWith("}")) {
                    log.object = JSON.parse(message)
                    
                    const logInfo = log.object as LogInfo
                    const transportInfo = log.object as TransportInfo
                    const deviceStatusInfo = log.object as DeviceStatusInfo
                    const classifierInfo = log.object as ClassifierInfo
                    const networkInfo = log.object as NetworkInfo
                    const inferenceInfo = log.object as InferenceInfo

                    logInfo.timestamp = log.timestamp

                    if (transportInfo.TotalTx !== undefined)
                    {
                        log.type = LogType.TRANSPORT
                        this.device.transport?.unshift(transportInfo)
                    }

                    if (deviceStatusInfo.ariane !== undefined)
                    {
                        log.type = LogType.DEVICE_STATUS

                        this.device.device_statuses?.unshift(deviceStatusInfo)

                        if (this.device.device_status === undefined) {
                            this.device.device_status = deviceStatusInfo
                        }
                    }

                    if (networkInfo.name === "NETWORK") {
                        log.type = LogType.NETWORK
                    }

                    if (classifierInfo.msg?.startsWith("CLASSIFER STATUS: Frames: "))
                    {
                        log.type = LogType.CLASSIFIER
                        classifierInfo.frames = Number.parseInt(classifierInfo.msg.replace("CLASSIFER STATUS: Frames: ",""))
                    }

                    if (inferenceInfo.meta !== undefined)
                    {
                        log.type = LogType.INFERENCE
                        this.device.inference?.unshift(inferenceInfo)
                    }
                }

                if (log.type === undefined)
                {
                    log.type = LogType.UNKNOWN
                }
                
            });
        },
        is_disabled(state?: string) : boolean {
            return state!=='disabled'            
        },
        reboot_device() {
            this.rebooting_error = false
            this.rebooting = true
            fetch(`${hosturl}/api/device/${this.device_uuid}/reboot`)
            .then(response => {
                this.rebooting = false
                if (response.status != 200) {
                    this.rebooting_error = true
                }
            })
        },
        restart_services() {
            this.restarting_error = false
            this.restarting = true
            fetch(`${hosturl}/api/device/${this.device_uuid}/restart`)
            .then(response => {
                this.restarting = false
                if (response.status != 200) {
                    this.restarting_error = true
                }
            })
        }
    },
    computed: {
        compactDisplay() {
            return (window.innerWidth < 1200)
        },
        device_boot() : string {
            const boot_time = this.device.device_status?.processor?.stats?.boot_time

            if (boot_time === undefined) {
                return "NA"
            }

            const epoch_date = ProcessorTools.get_epoch_date(boot_time)
            const now = new Date()
            let time_online = (now.getTime() - epoch_date.getTime()) / 1000
            const days = Math.floor(time_online / 86400)
            time_online -= (days * 86400)
            const hours = Math.floor(time_online / 3600)
            time_online -= (hours * 3600)
            const minutes = Math.floor(time_online / 60)
            time_online -= (minutes * 60)
            const seconds = Math.round(time_online * 100) / 100

            return `RUNNING FOR: ${days} Days, ${hours} Hours, ${minutes} Minutes and ${seconds} Seconds`
        },
        storage_color() : string {
            let storagePercent = 0

            if (this.device.drive !== undefined &&
                this.device.drive['Use%'] !== undefined
            ) {
                storagePercent = Number.parseFloat(this.device.drive['Use%']?.substring(0, this.device.drive['Use%'].length - 1))
            } else {
                if (this.device.metrics !== undefined &&
                    this.device.metrics.storage_total !== undefined &&
                    this.device.metrics.storage_usage !== undefined ) {
                    storagePercent = Math.round(((+this.device.metrics.storage_usage) * 100) / (+this.device.metrics.storage_total))
                } else {
                    return NotificationColors.DISABLED        
                }
            }

            if (storagePercent > 80) {
                return NotificationColors.FAIL
            }

            if (storagePercent > 60) {
                return NotificationColors.WARN
            }

            return NotificationColors.PASS
        },
        storage_size() : string {
            if (this.device.drive !== undefined &&
                this.device.drive.Size !== undefined) {
                return `${this.device.drive.Size}B`
            }

            if (this.device.metrics !== undefined &&
                this.device.metrics.storage_total !== undefined) {
                return `${this.device.metrics.storage_total}MB`
            }

            return "NA"
        },
        storage_percentage() : number {
            if (this.device.drive !== undefined &&
                this.device.drive['Use%'] !== undefined
            ) {
                return Number.parseFloat(this.device.drive['Use%']?.substring(0, this.device.drive['Use%'].length - 1))
            }

            if (this.device.metrics !== undefined &&
                this.device.metrics.storage_total !== undefined &&
                this.device.metrics.storage_usage !== undefined ) {
                return Math.round(((+this.device.metrics.storage_usage) * 100) / (+this.device.metrics.storage_total))
            }

            return 0
        },
        cpu_percentage() : number {
            if (this.device.cpu === undefined) {
                return 0
            }

            return this.device.cpu.usage
        },
        cpu_color() : string {
            if (this.device.cpu === undefined) {
                return NotificationColors.DISABLED
            }

            if (this.device.cpu.usage > 80) {
                return NotificationColors.FAIL
            }

            if (this.device.cpu.usage > 60) {
                return NotificationColors.WARN
            }

            return NotificationColors.PASS
        },
        cpu_count() : string {
            if (this.device.device_status?.processor === undefined ||
                this.device.device_status.processor.cpus === undefined) {
                return "USAGE"
            }

            return `${this.device.device_status.processor.cpus.length} CPUs`
        },
        memory_percentage() : number {
            if (this.device.device_status === undefined ||
                this.device.device_status.memory === undefined)
            {
                if (this.device.metrics !== undefined &&
                    this.device.metrics.memory_usage !== undefined &&
                    this.device.metrics.memory_total !== undefined)
                {
                    return Math.round ((+this.device.metrics.memory_usage * 100) / (+this.device.metrics.memory_total))
                }

                return 0
            }

            return Math.round(this.device.device_status.memory.USAGE.used)
        },
        memory_color() : string {
            let memoryPercent = 0
            if (this.device.device_status === undefined ||
                this.device.device_status.memory === undefined)
            {
                if (this.device.metrics === undefined ||
                    this.device.metrics.memory_usage === undefined ||
                    this.device.metrics.memory_total === undefined)
                {
                    return NotificationColors.DISABLED
                }

                memoryPercent = Math.round ((+this.device.metrics.memory_usage * 100) / (+this.device.metrics.memory_total))
            } else {
                memoryPercent = Math.round(this.device.device_status.memory.USAGE.used)
            }

            if (memoryPercent > 80) {
                return NotificationColors.FAIL
            }

            if (memoryPercent > 60) {
                return NotificationColors.WARN
            }

            return NotificationColors.PASS
        },        
        memory_size() : string {
            let memorySize = 0

            if (this.device.device_status === undefined ||
                this.device.device_status.memory === undefined) {
                if (this.device.metrics === undefined ||
                    this.device.metrics.memory_total === undefined) {
                    return "NA"                    
                }

                return `${this.device.metrics.memory_total}MB`
            } else {
                memorySize = this.device.device_status.memory.MemTotal.size

                if (this.device.device_status.memory.MemTotal.type === 'kB')
                {
                    memorySize = memorySize * 1000
                }

                if (this.device.device_status.memory.MemTotal.type === 'KB')
                {
                    memorySize = memorySize * 1024
                }
            }

            memorySize = Math.round((memorySize / 1073741824) * 10) / 10

            return `${memorySize}GB`
        },
        temperature_percent() : number {
            if (this.device.highTemp === undefined ||
                this.device.highTemp.max === 0)
            {
                return 0
            }            

            return Math.round((this.device.highTemp.input * 100) / this.device.highTemp.max)
        },
        temperature_color() : string {
            if (this.device.highTemp === undefined ||
                this.device.highTemp.max === 0)
            {
                return NotificationColors.DISABLED
            }

            const percent = Math.round((this.device.highTemp.input * 100) / this.device.highTemp.max)

            if (percent > 80) {
                return NotificationColors.FAIL
            }

            if (percent > 60) {
                return NotificationColors.WARN
            }

            return NotificationColors.PASS            
        },
        temperature_string() : string {
            if (this.device.highTemp === undefined)
            {
                return "NA"
            }            

            return `${Math.round(this.device.highTemp.input)} C`
        },
        temperature_source() : string {
            if (this.device.highTemp === undefined)
            {
                return "NA"
            }            

            return this.device.highTemp.source
        }
    }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .card-margin {
        min-width: 240px;
        margin: 8px;
    }

    .log-row {
        overflow-x: hidden;
        overflow-y: auto;
    }

    .log-row div:nth-of-type(odd) {
        background: #464f5b;
    }

    .device-badge {
        max-height: 48px;
    }

    .device-badge p {
        display: inline-block;
        margin: 0;
        padding: 0;
        position: relative;
        left: -12px;
    }

    .device-badge-invert img {
        filter: invert(1);
        z-index: 6;
    }

    .device-badge img {
        margin: 0;
        width: 20px;
        height: 20px;
        z-index: 1;
    }

    .device-links {
        width: 20%;
    }

    .links {
        padding: 8px;
        display: flex;
        align-content: space-between;
        gap: 16px;
    }

    img {
        width: 100%;
        height: 100%;
    }

    .box {
        width: 24px;
        height: 24px;
    }
</style>
