<template>
    <apexcharts :class="get_classes" 
                :width="width" 
                :height="height" 
                type="line" 
                :series="series"
                :options="options">
    </apexcharts>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import VueApexCharts from "vue3-apexcharts"
import { DeviceStatusInfo } from "@/models/Logs"
import { GraphingColors } from "@/models/UI"

export default defineComponent({
    name: 'DeviceMemoryGraph',
    props: {
        classes: String,
        width: String,
        height: String,
        id: String as () => "device-memory-graph",
        logs: Array as () => Array<DeviceStatusInfo>
    },
    data() {
        return {
        }
    },
    components: {
        apexcharts: VueApexCharts
    },
    methods: {
        build_memory_sets(logs : DeviceStatusInfo[] | undefined) {
            const memory_sets : 
            {
                [key: string] : number[]
            } = {}

            const memory_stamps : number[] = []

            memory_sets['Used'] = []
            memory_sets['Free'] = []
            memory_sets['Available'] = []

            logs?.forEach(status=>{
                if (status.memory === undefined ||
                    status.memory.USAGE === undefined) {
                    return
                }

                if (status.asctime !== undefined)
                {
                    memory_stamps.push(status.timestamp)
                }

                memory_sets['Used'].push(status.memory.USAGE.used)
                memory_sets['Free'].push(status.memory.USAGE.free)
                memory_sets['Available'].push(status.memory.USAGE.available)
            })

            return {
                sets: memory_sets,
                stamps: memory_stamps
            }
        }
    },
    computed: {
        memory_sets() {
            return this.build_memory_sets(this.logs)
        },
        options() {
            return {
                chart: {
                    id: this.id,
                    background: '#37404a'
                },
                xaxis: {
                    type: 'datetime',
                    label: {
                        show: true,
                    }
                },
                theme: {
                    mode: 'dark',
                }                
            };
        },
        series() {
            const temp_series = this.memory_sets
            const colors = GraphingColors
            const series = []
            let index = 0

            for (const key in temp_series.sets) {
                series.push({
                    name: key,
                    color: colors[index % colors.length],
                    data: temp_series.sets[key].map((temp,i)=>{ return [this.memory_sets.stamps[i], temp]})
                })
                index ++
            }

            return series
        },        
        get_classes() {
            if (this.classes === undefined) {
                return "w-100"
            }

            return this.classes
        }
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
