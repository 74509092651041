<template>
    <div v-if="loading" class="text-center">
        <div class="spinner-grow avatar-lg text-info" style="padding: 10%; margin: 10%;" role="status"></div> 
    </div>
    <div v-if="error">
        <h2>{{errorMessage}}!</h2>
    </div>
    <div v-if="ready" class="col-10 m-auto p-1">
        <DeviceConnections :device_uuid="device_uuid" />
        <DeviceNetworkConfig ethernet :device_uuid="device_uuid" />
        <DeviceNetworkConfig :device_uuid="device_uuid" />
        <DeviceCertUploader />
        <div class="card border border-secondary">
            <div class="card-header">
                <h4 class="text-dark text-center">DEVICE INFORMATION</h4>
            </div>
            <div class="card-body w-100 text-success">
                <div class="row h-100 align-items-center" v-if="query.model">
                    <h3 class="col-5 text-dark h-100">CONFIGURATION</h3>
                    <h3 class="dropdown col-7 text-md-right">{{query.model}}</h3>
                </div>
                <div class="row h-100 align-items-center" v-if="capture">
                    <h3 class="col-5 text-dark h-100">CAPTURE</h3>
                    <h3 class="dropdown col-7 text-md-right" v-if="capture">
                        DETECTED
                    </h3>
                    <h3 class="dropdown col-7 text-md-right" v-if="!capture">
                        NOT FOUND
                    </h3>
                </div>
                <template v-if="networks.length > 0">
                    <h3 class="col-5 text-info h-100 text-info">NETWORKS</h3>
                    <div class="row h-100 align-items-center">
                        <h4 class="col-4 h-100">NAME</h4>
                        <h4 class="col-3 h-100">TYPE</h4>
                        <h4 class="col-3 h-100">DEVICE</h4>
                    </div>
                    <div class="row h-100 align-items-center text-dark" v-for="network in networks" :key="network.id">
                        <h4 class="col-4 h-100">{{network.name}}</h4>
                        <h4 class="col-3 h-100">{{network.type}}</h4>
                        <h4 class="col-3 h-100">{{network.device}}</h4>
                    </div>
                </template>
                <template v-if="readings.length > 0">
                    <h3 class="col-5 text-info h-100">WIFI</h3>
                    <div class="row h-100 align-items-center">
                        <h4 class="col-4 h-100">SSID</h4>
                        <h4 class="col-1 h-100">SS</h4>
                        <h4 class="col-3 h-100">SPEED</h4>
                        <h4 class="col-2 h-100">TYPE</h4>
                    </div>
                    <div :class="{ 'row' : true, 'h-100' : true, 'align-items-center' : true, 'text-dark' : !wifi.InUse, 'bg-light' : wifi.InUse }" v-for="wifi in readings" :key="wifi.id">
                        <h4 class="col-4 h-100">{{wifi.SSID}}</h4>
                        <h4 class="col-1 h-100">{{wifi.strength}}</h4>
                        <h4 class="col-3 h-100">{{wifi.speed}} {{wifi.measure}}</h4>
                        <h4 class="col-2 h-100">{{wifi.type}}/{{wifi.encrypt}}</h4>
                    </div>
                </template>
            </div>
            <div class="card-footer text-center">
                <div class="row">
                    <a :class="{'col-12' : true, btn: true, 'btn-warning' : !query_error, 'btn-danger' : query_error, 'font-weight-bold' : true, }" @click="query_device()">
                        <div :class="{'spinner-grow': querying}">
                            <template v-if="!querying">
                                QUERY DEVICE
                            </template>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { QueryResponse } from '@/models/Devices';
import { hosturl } from '@/models/hub'
import { defineComponent } from "vue"
import { useRoute } from 'vue-router'
import DeviceCertUploader from '@/components/DeviceCertUploader.vue'
import DeviceNetworkConfig from '@/components/DeviceNetworkConfig.vue'
import DeviceConnections from '@/components/DeviceConnections.vue'

interface FieldMap {
    [key: string] : string
}

interface Network {
    name?:       string
    id?:         string
    type?:       string
    device?:     string
}

interface WiFiReading {
    InUse:      boolean
    id?:         string
    SSID?:       string
    type?:       string
    speed?:      string
    measure?:    string
    strength?:   string
    encrypt?:    string
}

export default defineComponent({
    name: 'DeviceConfiguration',
    data() {
        const route = useRoute();

        return {
            device_uuid: route.params.uuid as string,
            updating: false,
            updating_eth: false,
            update_error: false,
            querying: false,
            query_error: false,
            ready: true,
            loading: false,
            error: false,
            errorMessage: "",
            pfx_password: "",
            fields: {} as FieldMap,
            password: {} as FieldMap,
            query: {} as QueryResponse,
        }//import { NotificationColors } from "@/models/UI"
    },
    components: {
        DeviceConnections,
        DeviceCertUploader,
        DeviceNetworkConfig,
    },
    computed: {
        capture () : boolean | undefined {
            if (!this.query.devices) {
                return undefined;
            }

            let count = 0

            this.query.devices.forEach(device=>{
                if (device.includes("04:00.")) {
                    count ++
                }
            })

            return (count == 1)
        },
        networks () : Network[] {
            const networks : Network [] = []

            if (!this.query.networks) return networks

            const entries = [...this.query.networks]

            entries.shift()

            entries.forEach(entry=> {
                const fields = entry.split(" ").filter(item=>item.length > 0)
                const network = {} as Network

                network.device = fields.pop()
                network.type = fields.pop()
                network.id = fields.pop()
                network.name = fields.join(" ")

                networks.push(network)
            })

            return networks
        },
        readings () : WiFiReading[] {
            const readings : WiFiReading [] = []

            if (!this.query.wifi) return readings

            const networks = [...this.query.wifi]

            networks.shift()

            networks.forEach(wifi=> {
                const entries = wifi.split(" ")

                if (entries.length > 7) {
                    const entry = {} as WiFiReading

                    entry.InUse = entries.shift() == "*"

                    const fields = entries.filter(entry=>entry.length > 0)

                    entry.id = fields.shift()
                    entry.SSID = fields.shift()
                    entry.type = fields.shift()
                    entry.strength = fields.shift()
                    entry.speed = fields.shift()
                    entry.measure = fields.shift()
                    entry.strength = fields.shift()
                    entry.encrypt = fields.shift()
                    entry.encrypt = fields.shift()

                    readings.push(entry)
                }
            })

            return readings
        }
    },
    methods: {
        query_device() {
            if (this.querying) {
                return
            }

            this.querying = true
            this.query_error = false

            fetch(`${hosturl}/api/device/query/${this.device_uuid}`)
            .then(response => {
                if (response.status != 200) {
                    throw `STATUS: ${response.status} - Failed!`
                }

                return response.json()
            })
            .then(query => {
                this.query = query["query"] as QueryResponse
                this.querying = false
            })
            .catch(error => {
                this.errorMessage = error
                this.query_error = true
            })
            .finally(()=>{
                this.querying = false
            })
        }
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .card-margin {
        margin: 8px;
    }
</style>
