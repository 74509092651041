<template>
    <apexcharts :class="get_classes" 
                :width="width" 
                :height="height" 
                type="line" 
                :series="series"
                :options="options">
    </apexcharts>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import VueApexCharts from "vue3-apexcharts"
import { DeviceStatusInfo, TemperatureInfo } from "@/models/Logs"
import { GraphingColors } from "@/models/UI"

export default defineComponent({
    name: 'DeviceTempGraph',
    props: {
        classes: String,
        width: String,
        height: String,
        id: {
            type: String,
            default: () => "device-temp-graph"
        },
        logs: Array as () => Array<DeviceStatusInfo>
    },
    data() {
        return {
        }
    },
    components: {
        apexcharts: VueApexCharts
    },
    methods: {
        build_temp_sets(logs : DeviceStatusInfo[] | undefined) {
            const temp_sets : 
            {
                [key: string] : number[]
            } = {}

            const temp_stamps : number[] = []

            logs?.forEach(status=>{
                if (status.asctime !== undefined)
                {
                    temp_stamps.push(status.timestamp)
                }

                for (const key in status.temps) {
                    const tempInfo : TemperatureInfo = status.temps[key] as TemperatureInfo

                    if (tempInfo === undefined ||
                        !('Adapter' in tempInfo)) {
                        continue
                    }

                    for(const field in tempInfo)
                    {
                        if (field === "Adapter") {
                            continue
                        }

                        const sub_adapter  = `${field}`

                        if (!(sub_adapter in temp_sets)) {
                            temp_sets[sub_adapter] = []
                        }

                        for(const readings in tempInfo[field]) {
                            if (!readings.endsWith("_input") ||
                                typeof tempInfo[field][readings] !== 'number') {
                                continue
                            }

                            temp_sets[sub_adapter].push((tempInfo[field][readings] as unknown) as number)
                        }
                    }
                }
            })

            return {
                sets: temp_sets,
                stamps: temp_stamps
            }
        }
    },
    computed: {
        temp_sets() {
            return this.build_temp_sets(this.logs)
        },
        options() {
            return {
                chart: {
                    id: this.id,
                    background: '#37404a'
                },
                xaxis: {
                    type: 'datetime',
                    label: {
                        show: true,
                    }
                },
                theme: {
                    mode: 'dark',
                }                
            };
        },
        series() {
            const temp_series = this.temp_sets
            const colors = GraphingColors
            const series = []
            let index = 0

            for (const key in temp_series.sets) {
                series.push({
                    name: key,
                    color: colors[index % colors.length],
                    data: temp_series.sets[key].map((reading,i)=>{ return [this.temp_sets.stamps[i], reading]})
                })
                index ++
            }

            return series
        },        
        get_classes() {
            if (this.classes === undefined) {
                return "w-100"
            }

            return this.classes
        }
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
