<template>
    <div class="card border border-secondary m-1 card-margin">
        <div class="card-header">
            <h4 class="text-success text-center text-uppercase">{{service}}</h4>
        </div>
        <div class="card-body">
            <template v-if="!ready">
                <div class="row">
                    <div class="spinner-grow avatar-lg text-info col-4 loading-spinner" role="status">
                    </div>
                </div>
            </template>
            <template v-else>
                <div v-if="error" class="text-warning">{{error}}</div>
                <template v-for="(value, key) in logs" :key="key">
                    <div class="row text-info">
                        <a :href="value.link" target="_blank" class="log-link row w-100">
                            <div class="text-uppercase col-7">
                                <p>{{key}}</p>
                            </div>
                            <div class="col-5 text-right">
                                <p>{{value.count}}</p>
                            </div>
                        </a>
                    </div>
                </template>
            </template>
        </div>
        <!-- <div class="card-footer text-center">
            <h3 :style="get_style">{{get_status}}</h3>
        </div> -->
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { NotificationColors } from "@/models/UI"
import { hosturl } from "@/models/hub"
import { DataDogLogInfo } from "@/models/Logs"

interface LogEntry {
    [key: string] : {
        count: number
        link: string
    }
}

export default defineComponent({
    name: 'LogSummary',
    props: {
        service: String
    },
    data() {
        return {
            ready: false,
            error: '',
            logs: {} as LogEntry,
            logInfo: {} as DataDogLogInfo
        }
    },
    components: {
    },
    computed: {
        get_status() : string {
            if (this.logInfo === undefined) {
                return "NA"
            }

            return `ONLINE`
        },        
        get_style() : string {
            if (this.logInfo === undefined) {
                return `color: ${NotificationColors.DISABLED}`
            }

            return `color: ${NotificationColors.PASS}`
        },        
    },
    methods: {        
        async get_log_summary() {
            this.ready = false
            const url = new URL(`${hosturl}/api/datadog/aggregate`)

            url.searchParams.append("query",`service:*${this.service}*`)
            url.searchParams.append("facet","status")

            await fetch(url.toString())
                .then(response => {
                    if (response.status != 200)
                    {
                        throw `STATUS: ${response.status} - Failed!`
                    }
                    return response.json()
                })
                .then(logInfo => {
                    const end = Date.now()
                    const start = Date.now() - (86400 * 1000)

                    this.logInfo = logInfo
                    this.logs = {
                        error: { count: 0, link: `https://app.datadoghq.com/logs?query=service%3A${this.service}*%20status%3A${'error'}&index=%2A&from_ts=${start}&to_ts=${end}&live=true` },
                        warn: { count: 0, link: `https://app.datadoghq.com/logs?query=service%3A${this.service}*%20status%3A${'warn'}&index=%2A&from_ts=${start}&to_ts=${end}&live=true` },
                        info: { count: 0, link: `https://app.datadoghq.com/logs?query=service%3A${this.service}*%20status%3A${'info'}&index=%2A&from_ts=${start}&to_ts=${end}&live=true` },
                        totals: { count: 0, link: `https://app.datadoghq.com/logs?query=service%3A${this.service}*&index=%2A&from_ts=${start}&to_ts=${end}&live=true` },
                    }

                    this.logInfo.data.buckets.forEach(bucket => {
                        if (bucket.by.status in this.logs) {
                            this.logs[bucket.by.status].count = +bucket.computes['c0']
                            this.logs.totals.count += +bucket.computes['c0']
                        }
                    });
                    this.ready = true
                })
                .catch(error => {
                    this.error = error
                    this.logInfo = {} as DataDogLogInfo
                })
                .finally(()=>{
                    this.ready = true
                })
        }    
    },
    async mounted() {
        await this.get_log_summary()
    }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .card-margin {
        margin: 8px;
        min-width: 240px;
    }

    .loading-spinner {
        margin: auto;
        width: 150px;
        height: 150px;
    }

    a:hover,a:focus {
        color: lightgreen;
    }
</style>
