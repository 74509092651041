<template>
  <div class="content-page">
    <div v-if="!check_fleet(fleet)">
      <h3 class="text-warning">FLEET NOT AVAILABLE...</h3>
    </div>
    <template v-else>
      <div v-if="devicesReady" class="container-xl">
        <DeviceTable :devices="devices" :title="`${fleet.toUpperCase()} DEVICES`" :time="reportTime" :fleet="fleet" :canProvision="fleet === 'comet-pool'"/>
      </div>
      <div v-if="loadingDevices" class="text-center">
        <h3>Loading devices...</h3>
        <div class="spinner-grow avatar-lg text-info" style="padding: 10%; margin: 10%;" role="status"></div>
      </div>
      <div v-if="deviceError">
        <h2>{{ errorMessage }}!</h2>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import {hosturl} from '@/models/hub'
import { useRoute } from 'vue-router'
import DeviceTable from '@/components/DeviceTable.vue'
import { access_methods } from '@/models/hub'
import { Device } from '@/models/Devices'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FleetDevices',
  props: {},
  components: {
    DeviceTable,
  },
  data: () => {
    const route = useRoute();

    return {
      loadingDevices: false,
      devicesReady: false,
      deviceError: false,
      errorMessage: 'NA',
      reportTime: '',
      devices: Array<Device>(),
      fleet: route.params.fleet as string,
    }
  },
  methods: {
    ...access_methods,
    async refresh_devices() {
      await this.get_devices()
    },
    async get_devices() {
      this.devicesReady = false
      this.loadingDevices = true

      await fetch(`${hosturl}/api/${this.fleet}/devices`)
          .then(response => {
            if (response.status != 200) {
              throw `STATUS: ${response.status} - Failed!`
            }

            return response.json()
          })
          .then(devices => {
            this.reportTime = devices.time

            if (devices.devices) {
              this.devices = devices.devices
            }

            this.devicesReady = true
          })
          .catch(error => {
            this.devices = []
            this.errorMessage = `${hosturl}/api/${this.fleet}/devices : ${error}`
            this.deviceError = true
          })
          .finally(() => {
            this.loadingDevices = false;
          })
    },
  },
  async mounted() {
    await this.get_devices()
  }
})
</script>
