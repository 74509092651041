<template>
    <div class="overflow-auto h-100">
        <div v-for="error in get_errors(report)" :key="error.error">
            <h2 class="text-warning">ERROR: {{error.error}}</h2>
        </div>
        <table id="selection-datatable" class="table dt-responsive table-striped nowrap w-100">
            <thead>
                <tr>
                    <th>NAME</th>
                    <th>UUID</th>
                    <th>IPs</th>
                    <th>FAILURE</th>
                </tr>
            </thead>
            <tbody>
            <tr v-for="device in get_devices(report)" :key="device.name">
                <td>
                    <a target="_blank" :href="'/device/' + device.full_uuid">{{device.name}}</a>                    
                </td>
                <td>
                    <a target="_blank" :href="device.url">{{device.uuid}}</a>
                </td>
                <td>
                    <ul>
                        <li style="list-style: none;" v-for="ip in device.ips" :key="ip">
                        {{ip}}
                        </li>
                    </ul>
                </td>
                <td>
                    <a target="_blank" :href="datadog_link(sling, device.name,device.uuid)">
                        {{device.message}}
                    </a>
                </td>
            </tr>            
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'FailureTable',
    props: {
        report: Object,
        sling: Boolean,
    },
    emits: [
    ],
    components: {
    },
    methods: {
        datadog_link(sling, name, uuid) {
            if (sling) {
                return `https://app.datadoghq.com/logs?query=host%3Asling-${uuid}`
            }

            return `https://app.datadoghq.com/logs?query=host%3A${name}`
        },
        get_errors(devices) {
            return devices.filter(device => {
                if ('error' in device) {
                    return true;
                }

                return false;
            });
        },
        get_devices(devices) {
            return devices.filter(device => {
                if ('error' in device) {
                    return false;
                }

                return true;
            });
        }
    },
    computed: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
