<template>
  <div class="card border border-secondary">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-6">
          <h5 class="m-0">Device provisioning</h5>
        </div>

        <div class="col-6">
          <div class="text-right">
            Selected devices: {{ selectedDevices.length }}
          </div>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <div class="form-row">
            <div class="col-auto">
              <div class="form-group mb-0">
                <label for="provisioning-device-number" class="sr-only">Provision a number of devices</label>
                <input
                    style="min-width: 220px;"
                    type="number"
                    class="form-control"
                    id="provisioning-device-number"
                    placeholder="Provision the first X devices"
                    v-model="deviceNumber"
                >
                <small class="text-muted" v-if="deviceNumber > 0">
                  This field will override any device selection.
                </small>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 ml-auto">
          <div class="form-row justify-content-end align-items-center">
            <div class="col-auto">
              <div class="dropdown">
                <button
                    type="button"
                    data-toggle="dropdown"
                    class="btn btn-secondary dropdown-toggle"
                    style="min-width: 190px;"
                    :disabled="loadingNetConfigs"
                >
                  <span v-if="netConfig">{{netConfig.name}}</span>
                  <span v-else>WiFi Configuration</span>
                </button>

                <div class="dropdown-menu dropdown-menu-scrollable">
                  <a href="#" class="dropdown-item" v-for="config in netConfigs" :key="config.name" @click="(event) => {
                    event.preventDefault();
                    setNetConfig(config);
                  }">
                    {{config.name}}
                  </a>
                </div>
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group mb-0">
                <label for="provisioning-tags" class="sr-only">Tags</label>
                <input
                    style="min-width: 240px;"
                    type="text"
                    class="form-control"
                    id="provisioning-tags"
                    placeholder="Comma separated list of tags"
                    v-model="tags"
                >
              </div>
            </div>

            <div class="col-auto">
              <div class="text-right">
                <button
                  class="btn btn-success"
                  :disabled="(!deviceNumber && !selectedDevices.length) || submittingProvisioning || fields['PASSWORD'] !== passwordConfirm"
                  @click="onProvision"
                >
                  <span v-if="submittingProvisioning">Provisioning...</span>
                  <span v-else>Provision devices</span>
                </button>
              </div>
            </div>
          </div>

          <div class="form-row justify-content-end" v-if="netConfig">
            <div class="col">
              <div class="wifi-configuration-settings mt-2 p-2">
                <h5>WiFi configuration:</h5>
                <template v-for="field in netConfig.fields" :key="field">
                  <template v-if="field.includes('PASSWORD')">
                    <div class="form-group row">
                      <label
                          for="wifi-password"
                          class="col-form-label col-4 text-right"
                      >
                        {{ field }}
                      </label>
                      <div class="col-8">
                        <input
                            id="wifi-password"
                            type="password"
                            class="form-control"
                            :name="field"
                            v-model="fields[field]"
                            :placeholder="field"
                        >
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                          for="wifi-password-confirm"
                          :class="{'col-form-label col-4 text-right': true, 'text-danger': fields[field] !== passwordConfirm}"
                      >
                        Confirm {{ field }}
                      </label>
                      <div class="col-8">
                        <input
                            id="wifi-password-confirm"
                            type="password"
                            class="form-control"
                            :name="`${field}-CONFIRM`"
                            v-model="passwordConfirm"
                            :placeholder="`Confirm ${field}`"
                        >
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="form-group row">
                      <label :for="field" class="col-form-label col-4 text-right">{{ field }}</label>
                      <div class="col-8">
                        <input :id="field" type="text" class="form-control" name="field" v-model="fields[field]" :placeholder="field">
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="alert alert-danger mt-3 mb-0" role="alert" v-if="error">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import {NetworkConfig} from "@/models/Devices"
import {hosturl} from "@/models/hub";

interface FieldMap {
    [key: string] : string;
}

interface ProvisioningData {
  device_type: 'QA'|'PROD',
  uuids: string[];
  count?: number;
  net_config?: string;
  net_variables?: object;
  tags?: string;
}

export default defineComponent({
  name: 'DeviceProvisionForm',
  props: {
    selectedDevices: Array as () => string[],
  },
  data() {
    return {
      loadingNetConfigs: false,
      submittingProvisioning: false,
      error: '',
      netConfig: null as NetworkConfig | null,
      netConfigs: Array <NetworkConfig>(),
      fields: {} as FieldMap,
      passwordConfirm: undefined,
      tags: '',
      deviceNumber: null as number | null,
    }
  },
  methods: {
    async getNetworkConfigs() {
      this.loadingNetConfigs = true;

      try {
        const response = await fetch(`${hosturl}/api/network/configs`);

        if (response.status != 200) {
          throw `STATUS: ${response.status} - Failed!`;
        }

        const configs = await response.json();
        this.netConfigs = configs.wifi;
        this.error = '';
      } catch (error: unknown) {
        this.error = error as string;
      } finally {
        this.loadingNetConfigs = false;
      }
    },
    setNetConfig(config: NetworkConfig) {
      this.netConfig = config;
      this.fields = {};
    },
    async onProvision() {
      const data = {} as ProvisioningData;

      if (this.deviceNumber) {
        data.count = this.deviceNumber;
      }

      if (this.selectedDevices && !this.deviceNumber) {
        data.uuids = [...this.selectedDevices];
      }

      data.device_type = 'PROD';
      data.net_config = this.netConfig?.name ?? undefined;
      data.net_variables = this.netConfig && Object.keys(this.fields).length > 0 ? {...this.fields} : undefined;
      data.tags = this.tags;

      const confirmation = confirm(`You are about to provision ${data.count ? data.count : data.uuids.length} devices. Continue?`);

      if (!confirmation) {
        return;
      }

      try {
        this.submittingProvisioning = true;
        const response = await fetch(`${hosturl}/api/provision/devices`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (response.status != 200) {
            throw `STATUS: ${response.status} - Failed!`
        }
      } catch (error: unknown) {
        this.error = error as string;
      } finally {
        this.submittingProvisioning = false;
      }
    },
  },
  mounted() {
    this.getNetworkConfigs();
  }
});
</script>

<style>
  .wifi-configuration-settings {
    border-radius: 4px;
    border: 1px solid #47515d;
  }

  .dropdown-menu-scrollable {
    max-height: 340px;
    overflow-y: auto;
  }
</style>